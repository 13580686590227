import { createSlice } from "@reduxjs/toolkit";
import io from "socket.io-client";
import { baseURL } from "../../services/apiConstants";

export const socket = createSlice({
    name: "socket",
    initialState: {
        socket: null
    },
    reducers: {
        setSocket: (state, action) => {
            if (!state.socket) {
                state.socket = io(baseURL)
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { setSocket } = socket.actions;

export default socket.reducer;
