// third-party
import { combineReducers } from "redux";
import auth from "./auth";
import socket from "./socket";
import contract from "./contractSlice";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  socket,
  contract
});

export default reducers;
