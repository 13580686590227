import React from 'react'
import { Navigate } from 'react-router-dom';

const CommonRoutes = ({ isLoggedIn, children }) => {
    if (!isLoggedIn) {
        return children
    }
    return <Navigate to="/dashboard" replace />;

}

export default CommonRoutes