import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { deleteApi, get, post } from "../../../services/services";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Chat from "./chat";
import Loader from "../loader/loader";
import ChatImagePreview from "./chatImagePreview";
import ChatVideoPreview from "./videoImagePreview";
import DeleteChatDialog from "./deleteChatDialog";
import Subscribe from "../basic/subscribe";

export default function Message() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeUser, setActiveUser] = useState(
    location.state ? location.state.id : ""
  );
  const [loader, setLoader] = useState(true);
  const [chat, setChat] = useState([]);
  const [imgProgress, setImgProgress] = useState(0);
  const [vidProgress, setVidProgress] = useState(0);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentReceiver, setCurrentReceiver] = useState({});
  const [editChat, setEditChat] = useState({});
  const [userList, setUserList] = useState([]);
  const [temp, setTemp] = useState(false);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  // const { receiverId } = useParams();
  const [editing, setEditing] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.socket);

  let tempUserList = [];
  const handleImage = (e) => {
    const formData = new FormData();
    setImageFile(e.target.files[0]);
    formData.append("file", e.target.files[0]);
    post(`${ApiURL.imageUpload}`, formData, 1, {
      onUploadProgress: (progressEvent) => {
        setImgProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    }).then((response) => {
      setImage(response?.link);
    });
  };
  const handleVideo = (e) => {
    console.log("e", e.target.files);
    const formData = new FormData();
    setVideoFile(e.target.files[0]);
    formData.append("file", e.target.files[0]);
    post(`${ApiURL.chatVideo}`, formData, 1, {
      onUploadProgress: (progressEvent) => {
        setVidProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    }).then((response) => {
      console.log("response", response);
      setVideo(response.link);
    });
  };
  const deleteChatHandler = (message) => {
    socket.emit("deleteChat", { chatId: message.id });
  };
  const handleClickOpen = (id) => {
    setDeleteUserId(id);
    setOpenChat(true);
  };
  const handleClose = () => {
    setOpenChat(false);
  };
  useEffect(() => {
    if (activeUser) {
      console.log("findUser", activeUser);
      get(ApiURL.getChatActiveUser + "/" + activeUser).then((res) => {
        if (res.data) {
          console.log("res", userList);
          const findUser = userList.find((user) => user.id == res.data.id);
          console.log("findUser", res);
          if (findUser) {
            tempUserList.push(findUser);
            setCurrentReceiver(findUser);
          } else {
            tempUserList.push(res.data);
            // setUserList(prevState => [...prevState, res.data])
            setCurrentReceiver(res.data);
          }
        }
      });
    }
  }, [activeUser]);

  useEffect(() => {
    if (socket) {
      socket.on("deletedChat", ({ deletedChat }) => {
        if (chat.length > 0) {
          const chatCopy = [...chat];
          console.log(chatCopy);
          const newChat = chatCopy.filter(
            (_chat) => _chat.id !== deletedChat.id
          );
          setChat([...newChat]);
        }
      });
    }
  }, [socket, chat]);

  const editChatHandler = (message) => {
    setEditing(true);
    setCurrentMessage(message.body);
    setEditChat(message);
  };

  useEffect(() => {
    if (socket) {
      socket.on("editedChat", ({ editedChat }) => {
        console.log(editedChat);
        if (chat.length > 0) {
          const chatCopy = [...chat];
          const newChat = [];
          for (const _chat of chatCopy) {
            if (_chat.id === editedChat.id) {
              _chat.body = editedChat.body;
            }
            newChat.push(_chat);
          }

          setChat([...newChat]);
        }
      });
    }
  }, [socket, chat]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    get(`${ApiURL.getUserList}`).then((response) => {
      if (response.status) {
        let intersection = tempUserList.filter(
          (x) => !response?.users.includes(x)
        );
        console.log("intersection", intersection);
        if (intersection.length) {
          if (!userList.length) {
            setUserList((prevState) => [...prevState, ...intersection]);
          }
          if (userList.length > intersection.length) {
            setUserList((prevState) => [...prevState, ...intersection]);
          } else {
            let findUser = response?.users?.filter(
              (value) => value.id != intersection[0]?.id
            );
            setUserList((prevState) => [...prevState, ...findUser]);
          }
          setCurrentReceiver(intersection[0]);
        } else {
          console.log("aaya esle");
          if (response?.users?.length < userList?.length) {
            console.log("aaya sub if", intersection);
            setUserList(userList);
            setCurrentReceiver(userList[0]);
          } else {
            setUserList(response?.users);
            setCurrentReceiver(response?.users[0]);
          }
        }
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };
  // useEffect(() => { }, [])

  useEffect(() => {
    if (socket) {
      socket.on("newMessageResponse", ({ newMessage }) => {
        if (
          newMessage &&
          user &&
          user.id &&
          currentReceiver &&
          currentReceiver?.id
        ) {
          let newData = [];
          if (
            (newMessage &&
              currentReceiver &&
              currentReceiver.id &&
              newMessage.reciver_id &&
              newMessage.reciver_id.toString() ===
                currentReceiver.id.toString() &&
              newMessage.sender_id.toString() === user.id.toString()) ||
            (newMessage.sender_id.toString() ===
              currentReceiver.id.toString() &&
              newMessage.reciver_id.toString() === user.id.toString())
          ) {
            newData = [...chat, newMessage];
          } else {
            newData = [...chat];
          }
          setChat(newData);
        }
      });
    }
  }, [socket, currentReceiver, user, chat, setChat]);

  useEffect(() => {
    setLoader(true);
    get(`${ApiURL.getChat}/${currentReceiver?.id}`).then((response) => {
      setLoader(false);
      if (response.status) {
        setChat(response.data);
      } else {
        setLoader(false);
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  }, [currentReceiver]);

  const sendMessage = (e) => {
    e.preventDefault();

    if (editing) {
      socket.emit("editChat", {
        chatId: editChat.id,
        body: currentMessage,
      });
    } else {
      if (currentMessage.trim().length > 0 || image || video) {
        socket.emit("sendMessage", {
          senderId: user.id,
          receiverId: currentReceiver?.id,
          message: currentMessage,
          attechment: image,
          video: video,
        });
        setTemp(true);
        setImage(null);
        setVideo(null);
        getUserData();
      } else {
        console.log("lsknf");
        return toast.error("Please write a message", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    }
    setEditChat();
    setEditing(false);
    setCurrentMessage("");
    setImageFile("");
    setVideoFile("");
  };

  const deleteUserChat = (id) => {
    console.log("id", id);
    deleteApi(ApiURL.deleteUserChat + id).then((res) => {
      if (res.status) {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDeleteUserId(null);
        navigate("/dashboard");
      }
    });
  };

  return (
    <div className="container">
      <Navbar></Navbar>
      <div className="row pt-1">
        <div className="col-sm-5 d-none d-sm-block">
          <h4 className="ms-3">Messages</h4>
        </div>
        <div className="col-sm-7 d-none d-sm-block">
          <div className="right-head ms-auto d-flex align-items-center justify-content-sm-end">
            <div className="swip-btn me-3">
              <figure>
                <i className="ri-exchange-line"></i>
              </figure>
              <h4>Swap</h4>
            </div>
            <Menu></Menu>
          </div>
        </div>
      </div>
      <h2 className="text-center d-block d-sm-none">Messages</h2>
      {temp ? null : <Loader visible={loader} />}
      <ul className="mobile-chat-list d-sm-none">
        {console.log("user", userList)}
        {userList?.length > 0 &&
          userList?.map((data, i) => {
            return (
              <li className="">
                <Link
                  to={`/message-details/${data.id}`}
                  className="row clickable"
                >
                  <figure className="avatar p-0">
                    <img
                      src={
                        data?.image
                          ? baseURL + data?.image
                          : "/frontend/images/default.jpg"
                      }
                      alt="preview"
                    />
                  </figure>
                  <div
                    className="col-10 d-block d-sm-none d-flex align-items-center"
                    style={{ color: "#fff" }}
                  >
                    <h4 style={{ width: "100%" }}>
                      {data?.username}{" "}
                      <i
                        className="ri-chat-delete-line ri-lg"
                        style={{ float: "right" }}
                        onClick={() => handleClickOpen(data?.id)}
                      ></i>
                    </h4>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing </p> */}
                  </div>
                </Link>
              </li>
            );
          })}
        {(userList?.length == 0 || !currentReceiver) && (
          <li className="">There is no one to chat </li>
        )}
      </ul>
      <div className="row chat-page pt-lg-4 pt-sm-5">
        <div className="col-md-12 me-auto">
          <div className="row">
            <div className="col-md-9 ms-auto">
              <div class="chat-head-main d-flex align-items-center mb-2">
                <div class="chat-head-photo d-flex align-items-center me-3">
                  <figure class="avatar me-2">
                    <a href="#">
                      <img
                        src={
                          currentReceiver?.image
                            ? baseURL + currentReceiver?.image
                            : "/frontend/images/default.jpg"
                        }
                        alt="preview"
                      />
                    </a>
                  </figure>
                  <h4>{currentReceiver?.username}</h4>
                </div>
                {currentReceiver && (
                  <div class="chat-head-info d-flex align-items-center ms-auto">
                    {currentReceiver?.subscribeUsers == null ? (
                      <Subscribe
                        price={currentReceiver?.price}
                        creatorId={currentReceiver?.id}
                      ></Subscribe>
                    ) : (
                      <a href="#" class="btn btn-sm-boarder btn-subscribed ">
                        Subscribed
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 d-none d-sm-block">
          <div className="msg-list">
            <ul>
              {userList?.length > 0 &&
                userList?.map((data, i) => {
                  return (
                    <>
                      <li
                        className={
                          data?.id === currentReceiver?.id
                            ? "d-flex active-user"
                            : "d-flex"
                        }
                        style={{ alignItems: "center" }}
                        key={i}
                      >
                        <div className="me-3">
                          <figure className="avatar">
                            <Link to={"/profile-details/" + data?.id}>
                              <img
                                src={
                                  data?.image
                                    ? baseURL + data?.image
                                    : "/frontend/images/default.jpg"
                                }
                                alt="preview"
                              />
                            </Link>
                          </figure>
                        </div>
                        <div
                          className="last-chatinfo"
                          onClick={() => {
                            setCurrentReceiver(data);
                          }}
                        >
                          <h4>
                            {" "}
                            {data?.username}{" "}
                            <i
                              className="ri-chat-delete-line ri-lg"
                              onClick={() => handleClickOpen(data?.id)}
                              style={{ float: "right" }}
                            ></i>
                          </h4>
                          {/* <p>Lorem Ipsum is simply dummy text of the printing </p> */}
                        </div>
                      </li>
                      <DeleteChatDialog
                        open={openChat}
                        setOpen={setOpenChat}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        deleteUserChat={() => deleteUserChat(deleteUserId)}
                      />
                    </>
                  );
                })}
              {(userList?.length == 0 || !currentReceiver) && (
                <li className="">There is no one to chat </li>
              )}
            </ul>
          </div>
        </div>
        <div className="col-sm-8 d-none d-sm-block">
          <div className="msg-chat">
            <div className="chat-main-in">
              <div className="chat-main-head d-flex d-sm-none">
                <div className="chat-main-pro d-flex">
                  <div className="me-3">
                    <figure className="avatar">
                      <Link to={`/profile-details/${currentReceiver?.id}`}>
                        <img
                          src={
                            currentReceiver?.image
                              ? baseURL + currentReceiver?.image
                              : "/frontend/images/default.jpg"
                          }
                          alt={"user"}
                        />
                      </Link>
                    </figure>
                  </div>
                  <div className="chat-main-pro-head">
                    <h4>{currentReceiver?.username}</h4>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing </p> */}
                    <Link
                      to={`/message-details/${currentReceiver?.id}`}
                      className="btn btn-sm-boarder"
                    >
                      Subscribe
                    </Link>
                  </div>
                </div>
                <div className="token-send ms-auto">
                  <a href="#" className="d-flex align-items-center">
                    <span>Send tip</span>
                    <figure className="ms-2">
                      <img src="/frontend/images/logo-ic.png" alt="preview" />
                    </figure>
                  </a>
                </div>
              </div>
              <Chat
                chat={chat}
                loader={loader}
                user={user}
                deleteChatHandler={deleteChatHandler}
                editChatHandler={editChatHandler}
                setEditing={setEditing}
                imageFile={imageFile}
                videoFile={videoFile}
                setImageFile={setImageFile}
                setVideoFile={setVideoFile}
                setImgProgress={setImgProgress}
                imgProgress={imgProgress}
                setVidProgress={setVidProgress}
                vidProgress={vidProgress}
              />
              <div class="token-send ms-auto text-end my-2">
                <a href="#" class="d-inline-flex align-items-center">
                  <span>Send tip</span>
                  <figure class="ms-2">
                    <img src="/frontend/images/logo-ic.png" />
                  </figure>
                </a>
              </div>
              <div className="send-chat">
                <div className="send-comment d-flex">
                  <div className="me-3">
                    <figure className="avatar">
                      <img
                        src={
                          user?.image
                            ? baseURL + user?.image
                            : "/frontend/images/default.jpg"
                        }
                        alt="preview"
                      />
                    </figure>
                  </div>
                  <div className="form-group w-100">
                    <input
                      type="text"
                      name="currentMessage"
                      id="currentMessage"
                      value={currentMessage}
                      onChange={(event) =>
                        setCurrentMessage(event.target.value)
                      }
                      className="form-control form-control-in message-input"
                      placeholder="Write your message"
                      onKeyDown={(e) =>
                        (userList.length != 0 ||
                          Object.keys(currentReceiver).length !== 0) &&
                        e.key.toLowerCase() === "enter" &&
                        sendMessage(e)
                      }
                    />
                    <div className="image-video-chat">
                      <button>
                        <input
                          disabled={
                            userList.length === 0 ||
                            Object.keys(currentReceiver).length === 0
                          }
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImage(e)}
                        />
                        <i class="ri-image-line"></i>
                      </button>
                      <button>
                        <input
                          disabled={
                            userList.length === 0 ||
                            Object.keys(currentReceiver).length === 0
                          }
                          type="file"
                          accept="video/*"
                          onChange={(e) => handleVideo(e)}
                        />
                        <i class="ri-vidicon-line"></i>
                      </button>
                    </div>
                    <button
                      disabled={
                        userList.length === 0 ||
                        Object.keys(currentReceiver).length === 0
                      }
                      type="button"
                      onClick={(e) => sendMessage(e)}
                    >
                      <i className="ri-send-plane-2-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
