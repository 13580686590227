import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { get, post } from "../../../services/services";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Suggestions from "../suggestions/suggestions";
import Loader from "../loader/loader";
import Pagination from "react-mui-pagination";
import { useSelector } from "react-redux";
import Subscribe from "../basic/subscribe";
export default function Explore() {
  const [page, setMyPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [creatorList, setCreatorList] = useState([]);
  const [input, setInput] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [attributeData, setAttributeData] = useState([]);
  const [searchAttribute, setSearchAttribute] = useState("All");
  const { user } = useSelector((state) => state.auth);
  const search = (e) => {
    setInput(e.target.value);
  };
  const setPage = (e, p) => {
    setMyPage(p);
  };
  const searchAttr = (e) => {
    if (e) {
      setSearchAttribute(e.name == searchAttribute ? "All" : e.name);
    } else {
      setSearchAttribute("All");
    }
  };

  const attributes = () => {
    get(ApiURL.getAttributes).then((res) => {
      setAttributeData(res?.data);
    });
  };
  const list = () => {
    setLoader(true);
    get(
      `${
        ApiURL.getCreatorList
      }?search=${input}&attribute=${searchAttribute}&page=${page}&limit=${15}`
    ).then((response) => {
      setLoader(false);
      if (response.status) {
        setCreatorList(response.creators);
        setPageCount(response?.count);
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  useEffect(() => {
    attributes();
    if (!input.length) {
      list();
    }
  }, [input, page, searchAttribute]);

  const subscribeHandle = (creatorId) => {
    let obj = {
      user_id: user.id,
      creatorId: creatorId,
    };
    post(ApiURL.insertSubscribe, obj).then((resp) => {
      toast.success(resp.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      list();
    });
  };

  return (
    <div className="container">
      <Navbar></Navbar>
      <div className="row pt-3 d-block d-lg-none">
        <div className="right-head ms-auto d-flex justify-content-center">
          <div className="swip-btn me-1">
            <figure>
              <i className="ri-exchange-line"></i>
            </figure>
            <h4>Swap</h4>
          </div>
          <Menu></Menu>
        </div>
      </div>
      <div className="row pt-3 d-block d-lg-none">
        <div className="col-12 form-group input-with-btn">
          <input
            type="text"
            name="search"
            value={input}
            onChange={search}
            className="w-20 form-control bg-transparent form-control-in"
            placeholder="Creator search here..."
          />
          <button onClick={list}>
            <i className="ri-search-line"></i>
          </button>
        </div>
        <ul className="nav nav-tabs pt-3 tabs-filter">
          <li className="nav-item">
            <Link
              className={
                "all" == searchAttribute.toLowerCase()
                  ? "nav-link active"
                  : "nav-link"
              }
              aria-current="page"
              onClick={() => searchAttr()}
            >
              All
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                "featured" === searchAttribute.toLowerCase()
                  ? "nav-link active"
                  : "nav-link"
              }
              aria-current="page"
              onClick={() => searchAttr({ name: "FEATURED" })}
            >
              Featured
            </Link>
          </li>
          {attributeData.map((attribute) => {
            return (
              <li className="nav-item">
                <Link
                  className={
                    attribute?.name == searchAttribute
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current="page"
                  onClick={() => searchAttr(attribute)}
                >
                  {attribute?.name}
                </Link>
              </li>
            );
          })}
          <li className="nav-item">
            <Link
              className={
                "subscribe" === searchAttribute.toLowerCase()
                  ? "nav-link active"
                  : "nav-link"
              }
              aria-current="page"
              onClick={() => searchAttr({ name: "SUBSCRIBE" })}
            >
              Subscribed
            </Link>
          </li>
        </ul>
      </div>

      <Loader visible={loader} />
      <div className="row pt-1">
        <div className="col-sm-8 d-none d-lg-block ">
          <div className="form-group bg-transparent mb-4 mt-2 mb-sm-0 mt-sm-0 d-flex align-items-stretch justify-content-center input-with-btn">
            <input
              type="text"
              name=""
              value={input}
              onChange={search}
              className="form-control form-control-in"
              placeholder="Creator search here..."
            />
            <button onClick={list}>
              <i className="ri-search-line"></i>
            </button>
          </div>

          <ul className="nav nav-tabs mt-3 tabs-filter">
            <li className="nav-item">
              <Link
                className={
                  "all" == searchAttribute.toLowerCase()
                    ? "nav-link active"
                    : "nav-link"
                }
                aria-current="page"
                onClick={() => searchAttr()}
              >
                All
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  "featured" === searchAttribute.toLowerCase()
                    ? "nav-link active"
                    : "nav-link"
                }
                aria-current="page"
                onClick={() => searchAttr({ name: "FEATURED" })}
              >
                Featured
              </Link>
            </li>
            {attributeData.map((attribute) => {
              return (
                <li className="nav-item">
                  <Link
                    className={
                      attribute?.name == searchAttribute
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={() => searchAttr(attribute)}
                  >
                    {attribute?.name}
                  </Link>
                </li>
              );
            })}
            <li className="nav-item">
              <Link
                className={
                  "subscribe" === searchAttribute.toLowerCase()
                    ? "nav-link active"
                    : "nav-link"
                }
                aria-current="page"
                onClick={() => searchAttr({ name: "SUBSCRIBE" })}
              >
                Subscribed
              </Link>
            </li>
          </ul>
        </div>

        <div className="col-sm-4 d-none d-lg-block">
          <div className="right-head ms-auto d-flex align-items-center justify-content-sm-end">
            <div className="swip-btn me-3">
              <figure>
                <i className="ri-exchange-line"></i>
              </figure>
              <h4>Swap</h4>
            </div>
            <Menu></Menu>
          </div>
        </div>
      </div>
      <div className="row  pt-2 pt-sm-5">
        {!loader
          ? creatorList?.length > 0 &&
            creatorList?.map((creator) => (
              <div className="col-md-4">
                <div className="profile-box">
                  <figure>
                    {console.log("creator", creator)}
                    <Link
                      to={
                        creator?.id != user?.id &&
                        `/profile-details/${creator?.id}`
                      }
                    >
                      <img
                        src={
                          creator?.image
                            ? `${baseURL}${creator?.image}`
                            : "/frontend/images/default.jpg"
                        }
                        alt="preview"
                      />
                    </Link>
                  </figure>
                  <figcaption>
                    <div className="title-price">
                      <h3>
                        <Link
                          to={
                            creator?.id != user?.id &&
                            `/profile-details/${creator?.id}`
                          }
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {creator?.username}
                        </Link>
                      </h3>
                      <div
                        className="subscribe-btn"
                        style={{ marginTop: "10px" }}
                      >
                        {!creator?.subscribe_user && creator?.id != user?.id ? (
                          <Subscribe
                            data={creator}
                            price={creator?.price}
                            creatorId={creator?.id}
                          ></Subscribe>
                        ) : (
                          <a
                            href="javascript:void(0)"
                            className="btn btn-sm-boarder btn-subscribed"
                          >
                            Subscribed
                          </a>
                        )}
                      </div>
                    </div>
                  </figcaption>
                </div>
              </div>
            ))
          : null}
        {pageCount > 15 && (
          <Pagination
            page={page}
            setPage={setPage}
            perPage={15}
            total={pageCount}
            activeLinkColor="secondary"
          />
        )}
        {creatorList?.length < 1 ? (
          <p>
            <b>No data found.</b>
          </p>
        ) : null}
      </div>
    </div>
  );
}
