import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SignUpSchema } from "../../utils/validation";
import { get, post } from "../../services/services";
import { ApiURL } from "../../services/apiConstants";
import { toast } from "react-toastify";

const Sign = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const { userType } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAttributes()
  }, [])

  const getAttributes = () => {
    get(`${ApiURL.getAttributes}?type=create`).then((res) => {
      if (res.status) {
        setCategory(res.data)
      }
    })
  }

  const registerTapped = (values) => {
    setLoading(true);
    const email = values.email.trim();
    const password = values.password.trim();
    const userName = values.userName.trim();
    const fullName = values.fullName.trim();
    const confirmPassword = values.confirmPassword.trim();
    if (
      email.length > 0 &&
      password.length > 0 &&
      userName.length > 0 &&
      fullName.length > 0 &&
      confirmPassword.length > 0
    ) {
      post(ApiURL.register, values).then((response) => {
        if (response.status) {
          navigate("../login", { replace: true });
          toast.success(
            response?.response?.data?.message || response?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            }
          );
        } else {
          toast.error(response?.response?.data?.message || response?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        setLoading(false);
      });
    } else {
      toast.error("Please enter valid inputs", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          userName: "",
          fullName: "",
          confirmPassword: "",
          gender: "",
          age: false,
          role: userType,
        }}
        validationSchema={SignUpSchema}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          registerTapped(values);
          setStatus({ success: false });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <>
            <div
              className="jumbotron align-items-sm-center"
              style={{ display: "flex", minHeight: "100%", minHeight: "100vh" }}
            >
              <div className="container">
                <div className="row pt-5 pt-sm-0">
                  <div className="col-12 col-sm-8">
                    <div className="mx-auto">
                      <div className="signup-logo text-center">
                        <Link to={"/"}>
                          <img src="/frontend/images/logo.svg" alt="" />
                        </Link>
                        <h4>Sign up as a {userType}</h4>
                      </div>
                      <form onSubmit={handleSubmit} noValidate>
                        {/* <div className="signup-form-in"> */}
                        <div className="form-group mb-3">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email address"
                            value={values.email}
                            id="email"
                            onChange={handleChange}
                            style={
                              touched.email && errors.email
                                ? { border: "1px red solid", color: "white" }
                                : { color: "white" }
                            }
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <p
                              style={{ color: "red", margin: "5px 0" }}
                              id="standard-weight-helper-text-email"
                            >
                              {errors.email}
                            </p>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <select
                            className="form-control"
                            name="gender"
                            onChange={handleChange}
                            id="gender"
                            onBlur={handleBlur}
                            value={values.gender}
                          >
                            <option value="">Choose gender</option>
                            {category?.map((value) => {
                              return <option value={value?.id}>{value.name}</option>
                            })}
                          </select>
                          {touched.gender && errors.gender && (
                            <p
                              style={{ color: "red", margin: "5px 0" }}
                              id="standard-weight-helper-text-gender-login"
                            >
                              {errors.gender}
                            </p>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            name="userName"
                            id="userName"
                            className="form-control"
                            value={values.userName}
                            placeholder="Username"
                            onChange={handleChange}
                            style={
                              touched.userName && errors.userName
                                ? { border: "1px red solid", color: "white" }
                                : { color: "white" }
                            }
                            onBlur={handleBlur}
                          />
                          {touched.userName && errors.userName && (
                            <p
                              style={{ color: "red", margin: "5px 0" }}
                              id="standard-weight-helper-text-userName-login"
                            >
                              {errors.userName}
                            </p>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            name="fullName"
                            className="form-control"
                            value={values.fullName}
                            placeholder="Full name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="fullName"
                            style={
                              touched.fullName && errors.fullName
                                ? { border: "1px red solid", color: "white" }
                                : { color: "white" }
                            }
                          />
                          {touched.fullName && errors.fullName && (
                            <p
                              style={{ color: "red", margin: "5px 0" }}
                              id="standard-weight-helper-text-fullName-login"
                            >
                              {errors.fullName}
                            </p>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="password"
                            style={
                              touched.password && errors.password
                                ? { border: "1px red solid", color: "white" }
                                : { color: "white" }
                            }
                            value={values.password}
                          />
                          {touched.password && errors.password && (
                            <p
                              style={{ color: "red", margin: "5px 0" }}
                              id="standard-weight-helper-text-password-login"
                            >
                              {errors.password}
                            </p>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="password"
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Confirm password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="confirmPassword"
                            style={
                              touched.confirmPassword && errors.confirmPassword
                                ? { border: "1px red solid", color: "white" }
                                : { color: "white" }
                            }
                            value={values.confirmPassword}
                          />
                          {touched.confirmPassword &&
                            errors.confirmPassword && (
                              <p
                                style={{ color: "red", margin: "5px 0" }}
                                id="standard-weight-helper-text-confirmPassword-login"
                              >
                                {errors.confirmPassword}
                              </p>
                            )}
                        </div>
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            name="age"
                            type="checkbox"
                            id="flexCheckDefault"
                            value="age"
                            onChange={handleChange}
                            style={{ border: "white 1px solid" }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            I am above 18 years old
                          </label>

                          {touched.age && errors.age && (
                            <p
                              style={{ color: "red", margin: "5px 0" }}
                              id="standard-weight-helper-text-age-login"
                            >
                              {errors.age}
                            </p>
                          )}
                        </div>
                        {loading ? (
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-p w-100 w-sm-50"
                          >
                            <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-p w-100 w-sm-50"
                          >
                            Register
                          </button>
                        )}
                        <div className="login-already mt-2">
                          <p>
                            Already have an account?{" "}
                            <Link to={"/login"}>Log In</Link>
                          </p>
                        </div>
                        {/* </div> */}
                      </form>
                    </div>
                  </div>
                  <div className="col-sm-4 d-none d-sm-block signup-img-box">
                    <figure className="d-flex">
                      <img src="/frontend/images/img2.jpg" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default Sign;
