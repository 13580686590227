import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../../../assets/images/kiss.json'

export default function Loader({ visible }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (<>{
        visible ? <div className='loaderBg'>
            <img src='/frontend/images/spin.svg'></img>
        </div> : null
    }</>

    );
}