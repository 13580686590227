import { store } from "../app/store";
import instance from "./interceptors";


export const post = async (url, formData, type = 0, config = {}) => {
  console.log('export const ** ', formData)
  const { auth } = store.getState();
  let token_ = auth?.token || "";
  const options = {
    headers: {
      Accept: type ? "multipart/form-data" : "application/json",
      "Content-Type": type ? "multipart/form-data" : "application/json",
    },
  };

  let token = token_;
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return instance
    .post(url, formData, { ...options, ...config })
    .then(function (response) {
      if (response?.data?.success == 0) {
        // showErrorSnackBar(response?.data?.message || 'Something went wrong')
      }
      return response.data;
    })
    .catch(function (error) {
      // showErrorSnackBar(error?.message || 'Something went wrong')
      return error;
    });
};

export const put = async (url, formData, type = 0) => {
  const { auth } = store.getState();
  let token_ = auth?.token || "";


  const options = {
    headers: {
      Accept: type ? "multipart/form-data" : "application/json",
      "Content-Type": type ? "multipart/form-data" : "application/json",
    },
  };
  let token = token_;
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return instance
    .put(url, formData, options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // showErrorSnackBar(error?.message || 'Something went wrong')
      return error;
    });
};

export const patch = async (url, formData, type = 0) => {
  const { auth } = store.getState();
  let token_ = auth?.token || "";

  const options = {
    headers: {
      Accept: type ? "multipart/form-data" : "application/json",
      "Content-Type": type ? "multipart/form-data" : "application/json",
    },
  };
  let token = token_;
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return instance
    .patch(url, formData, options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // showErrorSnackBar(error?.message || 'Something went wrong')
      return error;
    });
};

export const get = async (url, type = 0) => {
  const { auth } = store.getState();
  let token_ = auth?.token || "";

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let token = token_;
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  // options.headers.demo = `Bearer`
  return instance
    .get(url, options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // showErrorSnackBar(error?.message || 'Something went wrong')
      return error;
    });
};

export const deleteApi = async (url, type = 0) => {
  const { auth } = store.getState();
  let token_ = auth?.token || "";

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let token = token_;
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  // options.headers.demo = `Bearer`
  return instance
    .delete(url, options)
    .then(function (response) {
      if (response?.data?.status == 0) {
        // showErrorSnackBar(response?.data?.message || 'Something went wrong')
      }
      return response.data;
    })
    .catch(function (error) {
      // showErrorSnackBar(error?.message || 'Something went wrong')
      return error;
    });
};
