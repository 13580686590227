import { useWeb3React } from "@web3-react/core";
import { injected } from "../../wallet/connector";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApiURL } from "../../../services/apiConstants";
import { setCuminuToken } from "../../../app/slices/contractSlice";
import { post as postApi } from "../../../services/services";

export default function Subscribe({ data, creatorId, price }) {
    const { contract, account, cuminuToken } = useSelector(state => state.contract);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { active, library, connector, activate, deactivate } = useWeb3React();
    async function connect() {
        try {
            await activate(injected);
        } catch (ex) {
            console.log('ex', ex);
        }
    }
    const subscribeHandle = async (post, creatorId) => {
        console.log("data", data);
        try {
            let obj = {
                user_id: user.id,
                creatorId: creatorId
            }
            if (post?.users?.accounts.length == 0) {
                return toast.error("User account address not found.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                });
            }
            // console.log(await contract[1].methods.buyToken(), "method")
            // const test = await contract[1].methods.transfer(post?.users?.accounts[0]?.account_address, Number(post?.users?.price)).send({ from: account })
            console.log("account", account)
            const test = await contract[1].methods.transfer(data?.users?.accounts[0]?.account_address, Number(price)).send({
                from: account
            });
            if (test.status) {
                postApi(ApiURL.insertSubscribe, obj).then((resp) => {
                    dispatch(setCuminuToken({ cuminuToken: Number(cuminuToken) - Number(price) }))
                    toast.success(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                    });
                });
            }
        } catch (error) {
            return toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        }
    }

    return (<div className="post-subscribe" onClick={() => connect()}>
        <button className="btn btn-sm-boarder" onClick={() => subscribeHandle(data, data?.users?.id)}>
            Subscribe for $ {price}
        </button>
    </div>);
}