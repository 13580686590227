import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { get } from "../../../services/services";
import { ApiURL } from "../../../services/apiConstants";

export default function DialogBox({
  open,
  setOpen,
  categories,
  searchCat,
  setCategoryData,
}) {
  const [changeCategory, setChangeCategory] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (changeCategory) {
      get(`${ApiURL.getCategory}?search=${changeCategory}`).then((res) => {
        setCategoryData(res?.data);
      });
    }
  }, [setCategoryData, changeCategory]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ backgroundColor: "black", minHeight: "60vh" }}>
        <DialogTitle id="alert-dialog-title">
          <input
            className="dialog-input"
            type="text"
            id="changeCategory"
            name="changeCategory"
            value={changeCategory}
            onChange={(event) => setChangeCategory(event.target.value)}
            placeholder="Search here"
          />
        </DialogTitle>
        <DialogContentText id="alert-dialog-description">
          <div className="filter-hashtag-custom mt-3">
            {categories &&
              categories.length > 0 &&
              categories?.map((category) => {
                return (
                  <button onClick={() => searchCat(category)}>
                    #{category?.name}
                  </button>
                );
              })}
            {categories.length === 0 && (
              <p style={{ fontSize: "20px", color: "#fff" }}>
                No category found
              </p>
            )}
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
