import EmojiPicker from "emoji-picker-react";
import { Formik } from "formik";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import {
  deleteApi,
  get,
  patch,
  post as postApi,
} from "../../../services/services";
import ImagePreview from "../../commn/ImagePreview";
import VideoPreview from "../../commn/VideoPreview";
import ImageCrop from "../ImageCrop/ImageCrop";
import Loader from "../loader/loader";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";
import Suggestions from "../suggestions/suggestions";
import Tiny from "../tinyEditor/tiny";
import DeletePostDialog from "./DeletePostDialog";
import CircularStatic from "../progress/progressBar";
import { Tooltip } from "@mui/material";
import Subscribe from "../basic/subscribe";
import ImageGallary from "../imageGallary";

export default function PostDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [post, setPost] = useState({});
  const { user } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.socket);
  const commentRef = useRef();
  const [reply, setReply] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [currentComment, setCurrentComment] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [privacy, setPrivacy] = useState("");
  const categoryRef = useRef();
  const [editor, setEditor] = useState("");
  const [postId, setPostId] = useState("");
  const [currentDeletePost, setCurrentDeletePost] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const [description, setDescription] = useState("");
  const [openPicker, setOpenPicker] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [imageBlob, setImageBlob] = useState(false);
  const [croppedImage, setCroppedImage] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [loader, setLoader] = useState(true);
  const [vidProgress, setVidProgress] = useState(0);
  const formRef = useRef();
  const txtRef = useRef();
  const [myPostData, setMyPostData] = useState({});
  const [openTipModal, setOpenTipModal] = useState(false);
  let replyEditor = null;
  const videoInputRef = useRef();
  const imageInputRef = useRef();

  useEffect(() => {
    setLoader(true);
    get(
      `${ApiURL.getPostDetails}/${id}?page=${currentPage}&limit=${limit}`
    ).then((res) => {
      setPost(res?.posts);
      setLoader(false);
    });
  }, [currentPage, limit, id]);

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  function timeSince(date) {
    const seconds =
      Math.floor((Date.now() - new Date(date).getTime()) / 1000) || 0;
    const interval = intervals.find((i) => i.seconds < seconds);
    const count = Math.floor(seconds / interval?.seconds) || 0;
    if (count === 0) {
      return "0 second ago";
    }
    return `${count} ${interval?.label}${count !== 1 ? "s" : ""} ago`;
  }

  const handleImageClose = () => {
    setOpenPicker(false);
    setCurrentImage(null);
    setImageUrl(null);
  };

  const deletePost = () => {
    deleteApi(`${ApiURL.deletePost}/${currentDeletePost.id}`).then(
      (response) => {
        if (response.status) {
          getPost();
          navigate("/dashboard");
          toast.success(response?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        handleClose();
      }
    );
  };

  const imageCloseHandler = () => {
    setCurrentImage("");
    setImageUrl("");
    setCroppedImage("");
    setImageBlob("");
    setImage([]);
  };

  const handleImageClickOpen = () => {
    setOpenPicker(true);
  };

  const videoUpload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    postApi(ApiURL.chatVideo, formData, 1).then((res) => {
      setVideo((prevState) => [...prevState, res.link]);
    });
  };

  const imageUpload = (e) => {
    const formData = new FormData();
    formData.append("file", imageBlob);
    postApi(ApiURL.chatImage, formData, 1).then((res) => {
      setImage((prevState) => [...prevState, res]);
    });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    setOpenPicker(true);
  };

  const videoCloseHandler = () => {
    setVideo([]);
  };

  const handleDoneClose = (croppedImageUrl) => {
    setCroppedImage(croppedImageUrl);
    imageUpload();
    setCurrentImage(null);
    setOpenPicker(false);
  };

  const findPostLike = () => {
    if (post && post.postLikes && post.postLikes.length) {
      for (const like of post.postLikes) {
        if (like.id === user.id) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const likeHandle = (postId) => {
    socket.emit("likePost", { userId: user.id, postId: postId });
  };

  const findLikes = (queryPost) => {
    if (queryPost && queryPost.postLikes && queryPost.postLikes.length > 1) {
      return `${queryPost.postLikes.length} likes`;
    } else if (
      queryPost &&
      queryPost.postLikes &&
      queryPost.postLikes.length === 1
    ) {
      return `${queryPost.postLikes.length} like`;
    } else {
      return;
    }
  };
  const emojiHandler = (e) => {
    setDescription((prevState) => (prevState += e.emoji));
  };
  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  const deleteHandler = (id) => {
    socket.emit("deleteComments", { commentId: id });
  };

  const getPost = () => {
    get(`${ApiURL.getPostDetails}/${id}`).then((res) => {
      setPost(res?.posts);
    });
  };
  useEffect(() => {
    getCategory();
  }, []);
  const commentLikeHandler = (data, postId) => {
    postApi(`${ApiURL.likeComment}`, data).then((response) => {
      if (response.status) {
        get(`${ApiURL.getPostDetails}/${postId}`).then((res) => {
          if (res.status) {
            const _post = { ...post };
            _post.commentsPosts = res?.posts?.commentsPosts;
            setPost({ ..._post });
          }
        });
      }
    });
  };

  const handleSelect = (e) => {
    setSelectCategory(e);
    setCategoryData(e);
  };

  const handleClickOpen = (post) => {
    setCurrentDeletePost(post);
    setOpen(true);
  };

  const getCategory = () => {
    get(ApiURL.getCategory).then((res) => {
      if (res.status) {
        let data = [];
        res?.data?.map((value) => {
          data.push({ name: value?.name, id: value?.id });
        });
        setCategory(data);
      }
    });
  };

  const handleUpdate = () => {
    if (editor?.length < 0 || editor == '<p><br data-mce-bogus="1"></p>') {
      toast.error("Please enter valid description", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return;
    }
    let temp = JSON.stringify(categoryData?.map((val) => val.id));

    if (temp.length == 0) {
      toast.error("Please select category", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return;
    }
    let obj = {
      id: postId,
      description: description,
      privacy: privacy,
      category: temp,
      video: JSON.stringify(video),
      image: JSON.stringify(image),
    };
    patch(ApiURL.updatePost, obj).then((response) => {
      if (response.status) {
        getPost();
        document.getElementById("closeModal").click();
        toast.success(response?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  const handleClose = () => {
    setCurrentDeletePost(null);
    setOpen(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on("deletedComment", ({ deletedComment }) => {
        setPost((prevState) => {
          return {
            ...prevState,
            commentsPosts: prevState?.commentsPosts?.filter(
              (commentData, index) => commentData?.id !== deletedComment?.id
            ),
          };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket && post && post.id) {
      socket.on("newComment", ({ postComment }) => {
        setPost((prevState) => ({
          ...prevState,
          commentsPosts: [
            ...postComment.commentsPosts,
            ...prevState.commentsPosts,
          ],
        }));

      });
    }
  }, [socket]);

  const showLike = async (id) => {
    setPost((prevState) => ({ ...prevState, showLikes: !prevState.showLikes }));
  };

  useEffect(() => {
    if (socket) {
      socket.on("newLiked", ({ postLiked }) => {
        if (postLiked && post) {
          setPost((prevState) => {
            const tempState = { ...prevState };
            if (tempState.id === tempState.id) {
              tempState.postLikes = postLiked.postLikes;
            }
            return tempState;
          });
        }
      });
    }
  }, [socket, setPost, post]);

  const replyHandler = (postId, commentId) => {
    const _post = post;
    for (const _comment of _post.commentsPosts) {
      if (_comment.id === commentId) {
        if (_comment.show === "1") {
          _comment.show = "0";
        } else {
          _comment.show = "1";
        }
      } else {
        _comment.show = "0";
      }
    }
    setPost({ ..._post });
  };

  const handleOpenTipModal = (data) => {
    setMyPostData(data);
    setOpenTipModal(true);
  };

  const editHandler = (content) => {
    const tempData =
      post?.id == content?.commentPostId
        ? { ...post, editComment: true }
        : { ...post, editComment: false };

    setPost(tempData);
    setCurrentComment(content);
  };

  const handleReply = (userId, postId, commentId) => {
    socket.emit("commentPost", {
      userId: userId,
      postId: postId,
      content: reply,
      parentId: commentId,
    });
    setReply("");
  };

  const commentPaginationHandler = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const updateComment = () => {
    socket.emit("editComments", {
      commentId: currentComment.id,
      content: currentComment.content,
    });
    // getPost();
  };

  useEffect(() => {
    if (socket) {
      socket.on("editComment", ({ editComment }) => {
        get(`${ApiURL.getPostDetails}/${editComment?.commentPostId}`).then(
          (res) => {
            if (res.status && post && post.id) {
              const _post = { ...post };
              console.log({ _post });
              _post.commentsPosts = res?.posts?.commentsPosts;
              _post.editComment = false;
              setPost({ ..._post });
            }
          }
        );
        setCurrentComment("");
      });
    }
  }, [socket, post]);

  const handleRemove = (e) => {
    setCategoryData(e);
  };

  const editPost = (data) => {
    let cat = data?.category?.map((v) => {
      return { id: v.id, name: v.name };
    });
    setCategoryData(cat);
    setDescription(data?.description);
    setImage(JSON.parse(data?.image) || []);
    setVideo(JSON.parse(data?.video) || []);
    setIsShow(true);
    // setEditor(data?.description);
    setPostId(data?.id);
    setPrivacy(data?.privacy);
  };

  const showComment = () => {
    const formattedPost = { ...post };
    if (formattedPost.show) {
      formattedPost.show = false;
    } else {
      formattedPost.show = true;
    }
    setPost({ ...formattedPost });
  };

  return (
    <>
      <div className="container">
        <Navbar></Navbar>
        <div className="row pt-3 d-block d-lg-none">
          <div className="right-head ms-auto d-flex justify-content-center">
            <div className="swip-btn me-1">
              <figure>
                <i className="ri-exchange-line"></i>
              </figure>
              <h4>Swap</h4>
            </div>
            <Menu></Menu>
          </div>
        </div>
        <Loader visible={loader} />
        <div className="row pt-2 pt-sm-5">
          <div className="col-sm-8 left-dashboard">
            <>
              {post && (
                <div className="post-box card">
                  <div className="card-inner">
                    <div className="post-header d-flex align-items-center mb-2">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure className="avatar">
                            <Link
                              to={
                                post?.users?.id != user?.id &&
                                `/profile-details/${post?.users?.id}`
                              }
                            >
                              <img
                                src={
                                  post?.users?.image != null
                                    ? baseURL + post?.users?.image
                                    : "/frontend/images/default.jpg"
                                }
                                alt="img"
                              />
                            </Link>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <Link
                              to={
                                post?.users?.id != user?.id &&
                                `/profile-details/${post?.users?.id}`
                              }
                            >
                              {post?.users?.username}
                            </Link>
                          </h4>
                          <span>{timeSince(post?.createdAt)}</span>
                        </div>
                      </div>
                      <div className="post-action-main ms-auto">
                        {post?.userId == user?.id ? (
                          <>
                          </>
                        ) : null}
                      </div>
                      {user?.id != post?.userId && !post?.subscribe_user ? (
                        <div className="ms-auto">
                          <Subscribe
                            data={post}
                            price={post?.users?.price}
                            creatorId={post?.users?.id}
                          ></Subscribe>
                        </div>
                      ) : null}
                    </div>
                    <div className="post-info">
                      <div className="post-text">
                        <div>{post?.description}</div>
                        {post?.media?.length > 0 && (
                          <ImageGallary images={post?.media} post={post} />
                        )}
                        {/* </Carousel> */}
                      </div>
                    </div>
                    <ul
                      style={{
                        margin: "10px 0",
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {post?.category?.map((val) => {
                        return (
                          // <li className="tags" onClick={() => searchCat(val)}>
                          <li className="tags">#{val.name}</li>
                        );
                      })}
                    </ul>
                    <div className="post-action">
                      <div className="d-flex">
                        <div
                          style={{ cursor: "pointer" }}
                          className="icon-with-text"
                        >
                          {" "}
                          {findPostLike(post) ? (
                            <button
                              className="iocn-btn"
                              onClick={() => likeHandle(post?.id)}
                            >
                              <i
                                className="ri-heart-fill like-heart"
                                style={{ color: "red" }}
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="iocn-btn"
                              title="Like Post"
                              onClick={() => likeHandle(post?.id)}
                            >
                              <i className="ri-heart-line"></i>
                            </button>
                          )}{" "}
                          {/* <p style={{ color: "#878787" }}> */}
                          <span
                            onClick={() => {
                              showLike(post.id);
                            }}
                          >
                            {findLikes(post)}
                          </span>
                          {/* </p> */}
                        </div>
                        <div
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="icon-with-text"
                          onClick={() => {
                            showComment(post.id);
                          }}
                        >
                          <button
                            className="iocn-btn"
                            style={{ marginRight: "10px" }}
                          >
                            <i className="ri-chat-3-line"></i>
                          </button>
                          <p style={{ color: "#878787" }}>
                            {post.commentCount > 0
                              ? post.commentCount + " comments"
                              : post.commentCount + " comment"}
                          </p>
                        </div>
                        {/* <i className="ri-chat-3-line"></i> 10 Comments */}
                        <div className="ms-auto">
                          {post?.userId != user?.id && (
                            <div className="token-send">
                              <a
                                href="javascript:void(0)"
                                className="d-flex align-items-center"
                              >
                                <span onClick={() => handleOpenTipModal(post)}>
                                  Send tip
                                </span>
                                <figure className="ms-2">
                                  <img
                                    src="/frontend/images/logo-ic.png"
                                    alt=""
                                  />
                                </figure>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {post.show && (
                      <>
                        <div
                          className="send-comment d-flex"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="me-3">
                            <figure className="avatar">
                              <img
                                height="40px"
                                width="40px"
                                style={{ borderRadius: "50%" }}
                                src={
                                  user?.image != null
                                    ? baseURL + user?.image
                                    : "/frontend/images/default.jpg"
                                }
                                alt="img"
                              />
                            </figure>
                          </div>
                          <Formik
                            innerRef={formRef}
                            initialValues={{
                              [`comment${post.id}`]: "",
                            }}
                            onSubmit={async (
                              values,
                              {
                                setErrors,
                                setStatus,
                                setSubmitting,
                                resetForm,
                                setFieldValue,
                              }
                            ) => {
                              const content =
                                values[`comment${post.id}`].trim();

                              if (content?.length > 0) {
                                socket.emit("commentPost", {
                                  userId: user.id,
                                  postId: post.id,
                                  content,
                                });
                              } else {
                                toast.error("Please enter valid comment");
                              }
                              resetForm();
                            }}
                          >
                            {({
                              errors,
                              handleChange,
                              handleSubmit,
                              values,
                              setFieldValue,
                            }) => (
                              <form
                                onSubmit={handleSubmit}
                                className="d-flex w-100"
                              >
                                <div className="form-group w-100">
                                  <input
                                    ref={commentRef}
                                    type="text"
                                    name={`comment${post.id}`}
                                    id={`comment${post.id}`}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `comment${post.id}`,
                                        event.target.value
                                      );
                                    }}
                                    className="form-control form-control-in"
                                    placeholder="Write your comment"
                                    value={values[`comment${post.id}`]}
                                  />
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-boarder mt-1 ms-1"
                                  disabled={
                                    values[`comment${post?.id}`]?.length === 0
                                  }
                                  style={{
                                    color: "rgb(249, 19, 129)",
                                    border: "1px solid",
                                  }}
                                >
                                  <i className="me-1 ri-send-plane-line align-middle"></i>
                                  Send
                                </button>
                              </form>
                            )}
                          </Formik>
                        </div>
                        {post?.commentsPosts &&
                          post?.commentsPosts?.length > 0 && (
                            <div className="comment-list-box">
                              {post?.commentsPosts?.map((comment, index) => (
                                <div
                                  key={index}
                                  className="comment-list d-flex mt-2"
                                >
                                  <div className="comment-list-in d-flex">
                                    <Link
                                      style={{ minWidth: "max-content" }}
                                      to={
                                        post?.users?.id != user.id &&
                                        `/profile-details/${comment?.usersComments?.id}`
                                      }
                                    >
                                      <figure className="avatar">
                                        <img
                                          src={
                                            comment?.usersComments?.image
                                              ? `${baseURL}${comment?.usersComments?.image}`
                                              : "/frontend/images/default.jpg"
                                          }
                                          alt="img"
                                        />
                                      </figure>
                                    </Link>
                                    <div className="comment-msg w-100 px-3">
                                      <Link
                                        to={
                                          post?.users?.id != user.id &&
                                          `/profile-details/${comment?.usersComments?.id}`
                                        }
                                      >
                                        <div
                                          className="profile-name"
                                          style={{
                                            fontWeight: "bold",
                                            color: "#F91381",
                                          }}
                                        >
                                          {comment?.usersComments?.username}
                                        </div>
                                      </Link>
                                      <div>{comment.content}</div>
                                      <span
                                        style={{
                                          fontSize: "smaller",
                                          color: "#7b7b7b",
                                        }}
                                      >
                                        {timeSince(comment.createdAt)}
                                      </span>
                                      {comment.show && comment.show === "1" && (
                                        <div className="reply-box">
                                          <div class="form-group d-flex w-100">
                                            <input
                                              type="text"
                                              name="reply"
                                              id="reply"
                                              value={reply}
                                              placeholder="Add a reply…"
                                              class="form-control form-control-in"
                                              onChange={(e) =>
                                                setReply(e.target.value)
                                              }
                                            />
                                            <button
                                              type="button"
                                              className="btn btn-boarder ms-1"
                                              style={{
                                                color: "rgb(249, 19, 129)",
                                                border: "1px solid",
                                                width: "100px",
                                              }}
                                              onClick={() =>
                                                handleReply(
                                                  user.id,
                                                  post.id,
                                                  comment.id
                                                )
                                              }
                                            >
                                              Reply
                                            </button>{" "}
                                          </div>
                                        </div>
                                      )}
                                      {comment.commentReply &&
                                        comment.commentReply?.length > 0 &&
                                        comment.commentReply?.map((reply) => (
                                          <>
                                            <Link
                                              style={{
                                                minWidth: "max-content",
                                              }}
                                              to={
                                                post?.users?.id != user.id &&
                                                `/profile-details/${reply?.usersComments?.id}`
                                              }
                                            >
                                              <figure className="avatar">
                                                <img
                                                  src={
                                                    reply?.usersComments?.image
                                                      ? `${baseURL}${comment?.usersComments?.image}`
                                                      : "/frontend/images/default.jpg"
                                                  }
                                                  alt="img"
                                                />
                                              </figure>
                                            </Link>
                                            <Link
                                              to={
                                                post?.users?.id != user.id &&
                                                `/profile-details/${reply?.usersComments?.id}`
                                              }
                                            >
                                              <div
                                                className="profile-name"
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "#F91381",
                                                }}
                                              >
                                                {reply?.usersComments?.username}
                                              </div>
                                            </Link>
                                            <div className="comment-list d-flex mt-2">
                                              <div className="comment-list-in d-flex">
                                                {reply.content}
                                              </div>
                                              <div className="comment-action ms-auto">
                                                {reply?.commentUserId ==
                                                  user.id && (
                                                  <button
                                                    className="iocn-btn"
                                                    title="Delete Reply"
                                                  >
                                                    <i
                                                      class="ri-delete-bin-fill"
                                                      aria-hidden="true"
                                                      onClick={() => {
                                                        deleteHandler(
                                                          reply?.id,
                                                          post.id
                                                        );
                                                      }}
                                                      style={{
                                                        color: "white",
                                                      }}
                                                    ></i>
                                                  </button>
                                                )}
                                                {reply?.commentUserId ==
                                                  user.id && (
                                                  <button
                                                    className="iocn-btn"
                                                    title="Edit Reply"
                                                  >
                                                    <i
                                                      class="ri-edit-fill"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "white",
                                                      }}
                                                      onClick={() =>
                                                        editHandler(reply)
                                                      }
                                                    ></i>
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                            <span
                                              style={{
                                                fontSize: "smaller",
                                                color: "#7b7b7b",
                                              }}
                                            >
                                              {timeSince(reply.createdAt)}
                                            </span>
                                          </>
                                        ))}
                                    </div>
                                  </div>
                                  <div className="comment-action ms-auto">
                                    {JSON.parse(
                                      comment?.likeCommentId
                                    )?.includes(user?.id) ? (
                                      <button className="iocn-btn">
                                        <i
                                          className="ri-heart-fill like-heart"
                                          style={{ color: "red" }}
                                          aria-hidden="true"
                                          onClick={() =>
                                            commentLikeHandler(comment, post.id)
                                          }
                                        ></i>
                                      </button>
                                    ) : (
                                      <button
                                        className="iocn-btn"
                                        title="Like Comment"
                                      >
                                        <i
                                          className="ri-heart-line"
                                          aria-hidden="true"
                                          onClick={() =>
                                            commentLikeHandler(comment, post.id)
                                          }
                                        ></i>
                                      </button>
                                    )}
                                    {comment?.commentUserId == user.id ? (
                                      <button
                                        className="iocn-btn"
                                        title="Delete Comment"
                                      >
                                        <i
                                          class="ri-delete-bin-fill"
                                          aria-hidden="true"
                                          onClick={() => {
                                            deleteHandler(comment?.id, post.id);
                                          }}
                                          style={{
                                            color: "white",
                                          }}
                                        ></i>
                                      </button>
                                    ) : null}
                                    <button
                                      className="iocn-btn"
                                      title="Reply Comment"
                                      onClick={() =>
                                        replyHandler(post.id, comment.id)
                                      }
                                    >
                                      <i
                                        class="ri-reply-fill"
                                        aria-hidden="true"
                                        // onClick={() => {
                                        //   deleteHandler(comment?.id);
                                        // }}
                                        style={{
                                          color: "white",
                                        }}
                                      ></i>
                                    </button>
                                    {comment?.commentUserId == user.id ? (
                                      <button
                                        className="iocn-btn"
                                        title="Edit Comment"
                                      >
                                        <i
                                          class="ri-edit-fill"
                                          aria-hidden="true"
                                          style={{
                                            color: "white",
                                          }}
                                          onClick={() => editHandler(comment)}
                                        ></i>
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                              <div className="d-flex w-100 form-group comment-reply mt-2">
                                {post?.editComment ? (
                                  <>
                                    {" "}
                                    <input
                                      ref={commentRef}
                                      value={currentComment.content}
                                      type="text"
                                      name={`editComment`}
                                      id={`editComment`}
                                      className="form-control form-control-in"
                                      placeholder="Edit your comment"
                                      onChange={(e) =>
                                        setCurrentComment({
                                          ...currentComment,
                                          content: e.target.value,
                                        })
                                      }
                                      autoFocus={post?.editComment}
                                    />{" "}
                                    <button
                                      type="button"
                                      className="btn btn-boarder mt-1 ms-1"
                                      onClick={() => updateComment(post.id)}
                                    >
                                      Update
                                    </button>{" "}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                    {console.log({ post: post.postLikes })}
                    {post.showLikes && (
                      <>
                        {post?.postLikes && post?.postLikes?.length > 0 && (
                          <div className="comment-list-box">
                            {post?.postLikes?.map((user, index) => (
                              <div
                                key={index}
                                className="comment-list d-flex mt-2"
                              >
                                <div className="comment-list-in d-flex">
                                  <Link
                                    style={{ minWidth: "max-content" }}
                                    to={
                                      post?.users?.id != user.id &&
                                      `/profile-details/${user?.id}`
                                    }
                                  >
                                    <figure className="avatar">
                                      <img
                                        src={
                                          user?.image
                                            ? `${baseURL}${user?.image}`
                                            : "/frontend/images/default.jpg"
                                        }
                                        alt="img"
                                      />
                                    </figure>
                                  </Link>
                                  <div className="comment-msg w-100 px-3">
                                    <Link
                                      to={
                                        post?.users?.id != user?.id &&
                                        `/profile-details/${user?.id}`
                                      }
                                    >
                                      <div
                                        className="profile-name"
                                        style={{
                                          fontWeight: "bold",
                                          color: "#F91381",
                                        }}
                                      >
                                        {user?.username}
                                      </div>
                                    </Link>
                                    <span
                                      style={{
                                        fontSize: "smaller",
                                        color: "#7b7b7b",
                                      }}
                                    >
                                      {timeSince(user.createdAt)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="d-flex w-100 form-group comment-reply mt-2">
                              {post?.editComment ? (
                                <>
                                  {" "}
                                  <input
                                    ref={commentRef}
                                    value={currentComment.content}
                                    type="text"
                                    name={`editComment`}
                                    id={`editComment`}
                                    className="form-control form-control-in"
                                    placeholder="Edit your comment"
                                    onChange={(e) =>
                                      setCurrentComment({
                                        ...currentComment,
                                        content: e.target.value,
                                      })
                                    }
                                    autoFocus={post?.editComment}
                                  />{" "}
                                  <button
                                    type="button"
                                    className="btn btn-boarder mt-1 ms-1"
                                    onClick={() => updateComment(post.id)}
                                  >
                                    Update
                                  </button>{" "}
                                </>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
          <Suggestions></Suggestions>
        </div>
      </div>
      <div
        className="modal fade bd-example-modal-lg"
        id="exampleModal"
        // tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Post
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <textarea
                  id="description"
                  name="description"
                  aria-label="maximum height"
                  onChange={handleChange}
                  className="form-control form-control-in"
                  placeholder="Write your thought..."
                  value={description || ""}
                  ref={txtRef}
                  style={{ lineHeight: "normal" }}
                />
              </div>
              <div className="button-c-post d-flex align-items-center justify-item-center p-2">
                <div className="button-c-p-g" style={{ width: "100%" }}>
                  <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                    <div
                      className="image-upload-wrap"
                      style={{ margin: "10px", width: "80%" }}
                    >
                      <button
                        onClick={imageCloseHandler}
                        type="button"
                        className="iocn-btn"
                      >
                        <i className="ri-close-line"></i>
                      </button>
                      {image &&
                        image?.map((img) => <ImagePreview imageValue={img} />)}
                    </div>
                    <div
                      className="video-upload-wrap"
                      style={{ margin: "40px 10px 10px 10px", width: "80%" }}
                    >
                      <button
                        onClick={videoCloseHandler}
                        type="button"
                        className="iocn-btn"
                      >
                        <i className="ri-close-line"></i>
                      </button>
                      {video &&
                        video?.map((vid) => <VideoPreview videoValue={vid} />)}
                    </div>
                    {vidProgress > 0 && vidProgress < 100 && (
                      <div className="upload-preview-box">
                        <CircularStatic progress={vidProgress} />
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <>
                      {" "}
                      <button
                        className="btn"
                        type="button"
                        onClick={() => imageInputRef.current.click()}
                      >
                        <i className="ri-image-fill"></i> <span>Image</span>
                      </button>
                      <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={onSelectFile}
                        hidden
                        ref={imageInputRef}
                        accept="image/*"
                      />
                    </>

                    {openPicker && imageUrl && (
                      <ImageCrop
                        type="image"
                        file={imageUrl}
                        // setImage={setImage}
                        handleClickOpen={handleImageClickOpen}
                        handleClose={handleImageClose}
                        open={openPicker}
                        setOpen={setOpenPicker}
                        handleDoneClose={handleDoneClose}
                        setImageBlob={setImageBlob}
                      />
                    )}

                    <>
                      <button
                        className="btn"
                        type="button"
                        onClick={() => videoInputRef.current.click()}
                      >
                        <i className="ri-video-add-line"></i> <span>Video</span>
                      </button>
                      <input
                        type="file"
                        id="video"
                        name="video"
                        onChange={(event) => {
                          videoUpload(event);
                          //  setFieldValue("video", event.target.files[0]);
                        }}
                        hidden
                        ref={videoInputRef}
                        accept="video/*"
                      />
                    </>

                    <button
                      type="button"
                      style={{ position: "relative" }}
                      className="btn"
                      onClick={() => setOpenEmoji(!openEmoji)}
                    >
                      <i class="ri-emotion-line"></i>
                      <span>Emoji</span>
                    </button>
                    {openEmoji && (
                      <EmojiPicker
                        emojiStyle={{ position: "absolute" }}
                        onEmojiClick={emojiHandler}
                        theme="dark"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <label>Category</label>
                <Multiselect
                  className="form-control"
                  options={category} // Options to display in the dropdown
                  ref={categoryRef}
                  displayValue="name"
                  onSelect={handleSelect}
                  selectedValues={categoryData}
                  onRemove={handleRemove}
                />
                <div className="form-group mt-3">
                  <label>Type</label>
                  <select
                    name="privacy"
                    id=""
                    className="form-control"
                    value={privacy}
                    onChange={(e) => setPrivacy(e.target.value)}
                  >
                    <option value="">Post Type</option>
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                id="closeModal"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-p"
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeletePostDialog
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        deletePost={deletePost}
      />
    </>
  );
}
