import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiURL } from "../../../services/apiConstants";
import { get } from "../../../services/services";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Suggestions from "../suggestions/suggestions";

function Notification() {
  const [lists, setlists] = useState([]);
  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = () => {
    get(`${ApiURL.getNotificationList}`).then((response) => {
      setlists(response?.data);
    });
  };

  const readNotification = (data) => {
    get(ApiURL.readNotification + "/" + data?.id).then((res) => {
      if (res) {
        getNotification();
      }
    });
  };

  return (
    <div className="container">
      <Navbar lists={lists}></Navbar>
      <div className="row pt-1">
        <div className="col-sm-5 d-none d-sm-block">
          <h4 className="mb-3 ms-3">Notifications</h4>
        </div>

        <div className="col-sm-7 d-none d-sm-block">
          <div className="right-head ms-auto d-flex align-items-center justify-content-sm-end">
            <div className="swip-btn me-3">
              <figure>
                <i className="ri-exchange-line"></i>
              </figure>
              <h4>Swap</h4>
            </div>
            <Menu></Menu>
          </div>
        </div>
      </div>
      <h2 className="text-center d-block d-sm-none">Notifications</h2>
      <div className="row pt-2 pt-sm-5">
        <div className="col-md-8 left-section">
          <div className="notifications-list">
            <ul>
              {lists?.map((list) => {
                return (
                  <li onClick={() => readNotification(list)}>
                    <a href="#">
                      <figure>
                        <i className="ri-notification-3-line"></i>
                      </figure>
                      <figcaption>
                        <p style={{ textTransform: "capitalize" }}>
                          <b>{list?.title}</b> <br /> {JSON?.parse(list?.body)}
                        </p>
                      </figcaption>
                    </a>
                  </li>
                );
              })}
              {lists?.length < 1 ? (
                <figcaption>
                  <p>
                    <b> Notification not found.</b>{" "}
                  </p>
                </figcaption>
              ) : null}
            </ul>
          </div>
        </div>
        <Suggestions></Suggestions>
      </div>
    </div>
  );
}

export default Notification;
