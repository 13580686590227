import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { get } from "../../../services/services";

export default function Suggestions() {
  const [creatorList, setCreatorList] = useState([]);
  useEffect(() => {
    get(`${ApiURL.getSuggestCreator}`).then((response) => {
      setCreatorList(response.creators);
    });
  }, []);

  return (
    <div className="right-part col-md-3 ms-auto">
      <div className="suggestions">
        {creatorList.length != 0 ? <h4>Suggestions</h4> : null}
        <ul>
          {creatorList?.map((data, i) => {
            return (
              <li key={i}>
                <div className="suggestions-head d-flex align-items-center">
                  <div className="suggestions-profile d-flex align-items-center">
                    <div className="me-2">
                      <figure className="avatar">
                        <Link to={`/profile-details/${data.id}`}>
                          <img
                            src={
                              data?.image == null
                                ? "/frontend/images/user1.png"
                                : baseURL + data?.image
                            }
                          />
                        </Link>
                      </figure>
                    </div>
                    <h4>
                      <Link to={`/profile-details/${data.id}`}>
                        {data?.username}
                      </Link>
                    </h4>
                  </div>
                  <div className="token-send ms-auto">
                    <a href="#" className="d-flex align-items-center">
                      <figure className="ms-2">
                        <img src="/frontend/images/logo-ic.png" />
                      </figure>
                    </a>
                  </div>
                </div>
                <Link to={`/profile-details/${data.id}`}>
                  <div className="suggestions-img">
                    <img
                      alt="user"
                      src={
                        data?.image == null
                          ? "/frontend/images/img2.png"
                          : baseURL + data?.image
                      }
                    />
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
