import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function Sidebar() {
  const location = useLocation();
  const pathName = location.pathname;
  const { user } = useSelector((state) => state.auth);

  return (
    <ul className="side-bar-menu">
      <li className={pathName == "/explore" ? "active" : ""}>
        <Link to={"/explore"}>
          <i className="ri-compass-3-line"></i> Explore
        </Link>
      </li>
      <li className={pathName == "/message" ? "active" : ""}>
        <Link to={"/message"}>
          <i className="ri-chat-1-line"></i> Messages
        </Link>
      </li>
      <li className={pathName == "/notification" ? "active" : ""}>
        <Link to="/notification">
          <i className="ri-notification-3-line"></i> Notifications
        </Link>
      </li>
      <li className={pathName == "/profile" ? "active" : ""}>
        <Link to={"/profile"} href="#">
          <i className="ri-user-line"></i> Profile
        </Link>
      </li>
      {user && user.role === "creator" && (
        <li className={pathName === "/earning" ? "active" : ""}>
          <Link to={"/earning"} href="#;">
            <i className="ri-money-dollar-circle-line"></i> Earnings
          </Link>
        </li>
      )}
      {user && user.role === "creator" && (
        <li className={pathName === "/create-post" ? "active" : ""}>
          <Link to={"/create-post"} href="#">
            <i className="ri-add-circle-line"></i> Create
          </Link>
        </li>
      )}
      {user && user.role === "creator" && (
        <li className={pathName === "/manage-subscription" ? "active" : ""}>
          <Link to={"/manage-subscription"} href="#">
            <i className="ri-add-circle-line"></i> Manage subscription
          </Link>
        </li>
      )}
    </ul>
  );
}
