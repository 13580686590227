import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function VerifyAccount({ open, sendLinkVerifyAccount, handleClose, values }) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ background: "#1e1e1e" }}>
                    <DialogContentText id="alert-dialog-description" style={{ color: "#fff" }}>
                        You want to verify this account?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ background: "#1e1e1e" }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => sendLinkVerifyAccount(values.email)} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
