import React from "react";

export default function Metamask({ visible }) {
  return (
    <>
      {visible ? (
        <p>
          Install meta mask wallet{" "}
          <a href="https://metamask.io/" target="_blank">
            click here
          </a>
        </p>
      ) : null}
    </>
  );
}
