import React, { useState } from "react";
import CircularStatic from "../progress/progressBar";
const ChatImagePreview = ({ file, imgProgress }) => {
  const [preview, setPreview] = useState(null);

  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }
  return (
    <>
      {imgProgress > 0 && imgProgress < 100 && (
        <div className="upload-preview-box">
          <CircularStatic progress={imgProgress} />
        </div>
      )}
      <img
        src={preview}
        alt="preview"
        variant="square"
        sx={{ width: "150px", height: "150px" }}
      />
    </>
  );
};
export default ChatImagePreview;
