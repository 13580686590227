



import { createSlice } from "@reduxjs/toolkit";


export const contract = createSlice({
    name: "contract",
    initialState: {
        contract: [],
        account: null,
        metamask: false,
        cuminuToken: 0
    },
    reducers: {
        setContract: (state, action) => {
            console.log("hklhss", action.payload)
            state.contract = [...state.contract, action.payload.contract, action.payload.contract1]
        },
        setAccount: (state, action) => {
            console.log("setAccount", action.payload)
            if (!state.account) {
                state.account = action.payload.account
            }
        },
        setMetaMask: (state, action) => {
            console.log("metamass", action.payload)
            if (!state.metamask) {
                state.metamask = action.payload.metamask
            }
        },
        setCuminuToken: (state, action) => {
            console.log("token001", action.payload)
            state.cuminuToken = action.payload.cuminuToken
        },
    },
});

// Action creators are generated for each case reducer function
export const { setContract, setAccount, setMetaMask, setCuminuToken } = contract.actions;

export default contract.reducer;
