import React, { useState } from "react";
import { baseURL } from "../../services/apiConstants";

const ImagePreview = ({ file, imageValue }) => {
    const [preview, setPreview] = useState(null);
    return (
        < img
            src={file != null ? baseURL + file : imageValue
            }
            alt="preview"
            variant="square"
            sx={{ width: "100% !important" }}
        />
    );
};

export default ImagePreview;
