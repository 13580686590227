import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { post as postApi } from "../../../services/services";
import { ApiURL } from "../../../services/apiConstants";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1e1e1e",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TipModal({
  setOpenTipModal,
  openTipModal,
  handleOpenTipModal,
  post,
}) {
  const { contract, account } = useSelector((state) => state.contract);
  const [description, setDescription] = React.useState("");
  const handleClose = () => setOpenTipModal(false);
  const handleChange = (e) => setDescription(e.target.value);
  const { user } = useSelector((state) => state.auth);

  const addToken = async () => {
    if (Number(description) < 0 || description.trim().length <= 0) {
      return toast.error("Please enter vaild number", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
    if (post?.users?.accounts.length == 0) {
      return toast.error("User account address not found.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
    let test = await contract[1].methods
      .transfer(post?.users?.accounts[0]?.account_address, Number(description))
      .send({ from: account });
    if (test.status) {
      let obj = {
        to_id: post?.users?.id,
        from_id: user.id,
        price: description,
        post_id: post.id,
      };
      let store = await postApi(ApiURL.postTips, obj);
      if (store.status) {
        handleClose();
        setDescription("");
        toast.success(store.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      } else {
        toast.error(store.error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <div>
      <Modal
        open={openTipModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Send Tip
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="form-group mt-3">
              <label>Token</label>
              <input
                type="number1"
                className="form-control form-control-in"
                name="token"
                placeholder="Enter number of token"
                onChange={handleChange}
                id="token"
                value={description}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <button
                type="button"
                id="closeModal"
                className="btn btn-secondary mt-3 "
                onClick={() => handleClose()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-p mt-3 ms-2"
                onClick={() => addToken()}
              >
                Submit
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
