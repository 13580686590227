import * as React from "react";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

export default function DetailGallery({ open, setOpen, data }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ backgroundColor: "transparent", border: "none" }}
      >
        <Box className="gallery-view-main">
          <div className="gallery-close" onClick={handleClose}>
            <i class="ri-close-circle-fill ri-lg"></i>
          </div>
          <Carousel
            className="gallery-view-box"
            fullHeightHover={false}
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysVisible={data.length > 1}
          >
            {data &&
              data.length > 0 &&
              data.map((img) => (
                <>
                  {img.type === "image" && <img src={img.originalImage} />}
                  {img.type === "video" && (
                    <video
                      controls
                      controlsList="nodownload"
                      autoPlay
                      style={{ width: "100%" }}
                    >
                      <source src={img.url} />
                    </video>
                  )}
                </>
              ))}
          </Carousel>
        </Box>
      </Modal>
    </>
  );
}
