import React, { useState } from "react";

const VideoPreview = ({ file, videoValue }) => {
  const [preview, setPreview] = useState(null);

  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }


  return (
    (preview || videoValue) && (
      <video style={{ maxWidth: "100%" }} controls >
        <source src={preview || videoValue} />
      </video>
    )
  );
};

export default VideoPreview;
