import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL } from "../../../services/apiConstants";
import { post } from "../../../services/services";
import {
  ChangePasswordScheema,
  UpdateProfile,
} from "../../../utils/validation";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";

export default function ChangePassword() {
  const { user } = useSelector((state) => state.auth);
  const formState = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="container">
      <Navbar></Navbar>
      <div className="row pt-1">
        <div className="col-sm-8 d-none d-sm-block">
          <h4 className="ms-3">Change Password</h4>
        </div>

        <div className="col-sm-4 d-none d-sm-block">
          <div className="right-head ms-auto d-flex align-items-center justify-content-sm-end">
            <div className="swip-btn me-3">
              <figure>
                <i className="ri-exchange-line"></i>
              </figure>
              <h4>Swap</h4>
            </div>
            <Menu></Menu>
          </div>
        </div>
      </div>
      <h2 className="text-center d-block d-sm-none">Change Password</h2>
      <div className="row pt-2 pt-sm-5">
        <div className="col-sm-8 d-block d-sm-block">
          <Formik
            initialValues={formState}
            validationSchema={ChangePasswordScheema}
            onSubmit={(
              values,
              { setErrors, setStatus, setSubmit, resetForm }
            ) => {
              setLoading(true);
              post(ApiURL.changePassword, values).then((response) => {
                if (response.status) {
                  toast.success(response?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  resetForm();
                  navigate("/dashboard");
                } else {
                  toast.error(response?.response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              });
              setLoading(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="profile-p-form">
                {console.log("onSubmit *** ", errors)}
                <div className="form-group mb-3">
                  <label>Old Password</label>
                  <input
                    type="password"
                    name="oldPassword"
                    className="form-control form-control-in"
                    value={values.oldPassword}
                    onChange={handleChange}
                    placeholder="Old Password"
                  />
                  {touched.oldPassword || errors.oldPassword ? (
                    <p
                      style={{ color: "red", margin: "5px 0" }}
                      error
                      id="standard-weight-helper-text-oldPassword-login"
                    >
                      {errors.oldPassword}
                    </p>
                  ) : null}
                </div>

                <div className="form-group mb-3">
                  <label>New Password</label>
                  <input
                    type="password"
                    className="form-control form-control-in"
                    name="newPassword"
                    value={values.newPassword}
                    placeholder="New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="newPassword"
                  />
                  {touched.newPassword || errors.newPassword ? (
                    <p
                      style={{ color: "red", margin: "5px 0" }}
                      error
                      id="standard-weight-helper-text-newPassword-login"
                    >
                      {errors.newPassword}
                    </p>
                  ) : null}
                </div>
                <div className="form-group mb-3">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    className="form-control form-control-in"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="confirmPassword"
                  />
                  {touched.confirmPassword || errors.confirmPassword ? (
                    <p
                      style={{ color: "red", margin: "5px 0" }}
                      error
                      id="standard-weight-helper-text-confirmPassword-login"
                    >
                      {errors.confirmPassword}
                    </p>
                  ) : null}
                </div>
                <button type="submit" className="btn btn-p w-100">
                  Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-sm-4 d-none d-sm-block">
          <div className="suggestions ms-sm-5">
            <h2 className="d-block d-sm-none">Suggestions</h2>
            <h4 className="d-none d-sm-block">Suggestions</h4>
            <ul>
              <li>
                <div className="suggestions-head d-flex align-items-center">
                  <div className="suggestions-profile d-flex align-items-center">
                    <div className="profile-img me-3">
                      <figure>
                        <a href="#">
                          <img src="/frontend/images/user1.png" alt="img" />
                        </a>
                      </figure>
                    </div>
                    <h4>
                      <a href="#">Gregory Bell</a>
                    </h4>
                  </div>
                  <div className="token-send ms-auto">
                    <a href="#" className="d-flex align-items-center">
                      <figure className="ms-2">
                        <img src="/frontend/images/logo-ic.png" alt="img" />
                      </figure>
                    </a>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <img src="/frontend/images/img2.png" alt="img" />
                </div>
              </li>
              <li>
                <div className="suggestions-head d-flex align-items-center">
                  <div className="suggestions-profile d-flex align-items-center">
                    <div className="profile-img me-3">
                      <figure>
                        <a href="#">
                          <img src="/frontend/images/user1.png" alt="img" />
                        </a>
                      </figure>
                    </div>
                    <h4>
                      <a href="#">Gregory Bell</a>
                    </h4>
                  </div>
                  <div className="token-send ms-auto">
                    <a href="#" className="d-flex align-items-center">
                      <figure className="ms-2">
                        <img src="/frontend/images/logo-ic.png" alt="img" />
                      </figure>
                    </a>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <img src="/frontend/images/img2.png" alt="img" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
