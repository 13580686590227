import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { get } from "../../../services/services";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Suggestions from "../suggestions/suggestions";
import PostList from "./PostList";
import Loader from "../loader/loader";
import DialogBox from "../dialogBox/dialogBox";
import Multiselect from "multiselect-react-dropdown";
import SearchResult from "./searchResult";

export default function Dashboard() {
  const is_type = useSelector((state) => state?.token?.userType);
  const [posts, setPosts] = useState([]);
  const [newLike, setNewLike] = useState({});
  const [input, setInput] = useState("");
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCommentPage, setCurrentCommentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [postPage, setPostPage] = useState(1);
  const [postLimit, setPostLimit] = useState(10);
  const [postCount, setPostCount] = useState(0);
  const [mySearch, setMySearch] = useState(false);
  const [attributeData, setAttributeData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);
  const [searchAttribute, setSearchAttribute] = useState("All");
  const [open, setOpen] = useState(false);
  const [categorySummaryData, setCategorySummaryData] = useState(null);
  const [showMoreInput, setShowMoreInput] = useState(false);
  const categoryRef = useRef();
  const [category, setCategory] = useState([]);
  const [creatorList, setCreatorList] = useState([]);
  const [currentCreator, setCurrentCreator] = useState("");
  const [showOption, setShowOption] = useState(false);
  const [searchClicked, setSearchClicked] = useState(true);
  const [listClicked, setListClicked] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.socket);
  const { cuminuToken } = useSelector((state) => state.contract);
  const [hasData, setHasData] = useState(true);
  const [myData, setMyData] = useState(0);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const inputRef = useRef();

  const searchCat = (e) => {
    setSearchCategory((prevState) => {
      return [...prevState, e.name];
    });
    setListClicked((prevState) => [...prevState, { name: e.name, id: e.id }]);
    setOpen(false);
  };

  const getCategory = () => {
    get(ApiURL.getCategory).then((res) => {
      if (res.status) {
        let data = [];
        res?.data?.map((value) => {
          data.push({ name: value?.name, id: value?.id });
        });
        setCategory(data);
      }
    });
  };

  const commentPaginationHandler = (postId, type) => {
    if (type === "less") {
      setCurrentCommentPage(1);
    } else {
      setCurrentCommentPage((prevState) => prevState + 1);
    }
    get(
      `${ApiURL.getPostComments}/${postId}?commentPage=0&limit=${(currentCommentPage + 1) * 5
      }`
    ).then((res) => {
      setPosts((prevState) => {
        const data = [];
        prevState.map((post) => {
          if (post.id.toString() === postId.toString()) {
            post.commentsPosts = res.posts;
            data.push(post);
          } else {
            data.push(post);
          }
          return data;
        });
        return data;
      });
    });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const searchAttr = (e) => {
    if (e) {
      setSearchAttribute(e.name == searchAttribute ? "All" : e.name);
    } else {
      setSearchAttribute("All");
    }
  };

  const search = (e) => {
    setInput(e.target.value);
  };

  const searchData = () => {
    setMySearch(true);
    getPostData();
  };

  useEffect(() => {
    getPostData();
  }, [searchAttribute, searchCategory, cuminuToken]);

  useEffect(() => {
    if (socket && user && user.id) {
      socket.emit("joinRoom", { userId: user.id });
    }
  }, [user, socket]);
  // const handleReset = () => {
  //     setInput('');
  //     setMySearch(false);
  // }

  useEffect(() => {
    attributes();
    categories();
  }, []);

  const attributes = () => {
    get(ApiURL.getAttributes).then((res) => {
      setAttributeData(res?.data);
    });
  };

  const categories = () => {
    get(ApiURL.getCategory).then((res) => {
      setCategoryData(res?.data);
      if (res.data.length > 0) {
        setCategorySummaryData(res?.data.slice(0, 10));
      }
    });
  };

  const handleRemove = (e) => {
    if (e && e.length > 0) {
      setSearchCategory(e.map((ps) => ps.name));
      setListClicked([...e]);
    } else {
      setSearchCategory([]);
      setListClicked([]);
    }
  };

  const handleSelect = (e) => {
    setSearchCategory((prevState) => [...prevState, e[e.length - 1].name]);
  };

  const getPostData = (_limit = 0) => {
    setLoader(true);
    let user = currentCreator;
    if (input.length === 0) {
      setCurrentCreator("");
      user = "";
    }
    setPostLimit(postLimit + _limit);
    get(
      `${ApiURL.getPosts
      }?page=${currentPage}&limit=${limit}&search=${user}&attribute=${searchAttribute.toLowerCase() === "all" ? "" : searchAttribute
      }&category=${searchCategory}&postPage=${postPage}&postLimit=${postLimit + _limit
      }`
    ).then((response) => {
      setLoader(false);
      if (response.status) {
        setPostCount(response?.count);
        setHasData(response?.count - postLimit + _limit > 0);
        setPosts(
          response?.posts?.map((item) => {
            return { ...item, editComment: false };
          })
        );
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  useEffect(() => {
    if (input.length > 0) {
      (async () => {
        const { creators } = await get(
          `${ApiURL.getCreatorList}?search=${input}&attribute=${searchAttribute}&category=${searchCategory}`
        );
        if (creators && creators.length > 0) {
          setCreatorList(creators);
        }
      })();
    }
    if (input.length === 0) {
      // setSearchCategory("")
      get(
        `${ApiURL.getPosts
        }?page=${currentPage}&limit=${limit}&search=&attribute=${searchAttribute.toLowerCase() === "all" ? "" : searchAttribute
        }&category=${searchCategory}&postPage=${postPage}&postLimit=${postLimit}`
      ).then((response) => {
        setLoader(false);
        if (response.status) {
          setPostCount(response?.count);
          setPosts(
            response?.posts?.map((item) => {
              return { ...item, editComment: false };
            })
          );
        } else {
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
      });
    }
  }, [input]);

  const handleArrowKey = (event) => {
    let nextIndexCount = -1;
    // move down
    if (event.keyCode === 38)
      nextIndexCount =
        (focusedIndex + creatorList.length - 1) % creatorList.length;
    // move up
    if (event.keyCode === 40)
      nextIndexCount = (focusedIndex + 1) % creatorList.length;

    if (event.keyCode === 13) {
      searchByUserHandler(creatorList[focusedIndex]);
      event.target.blur();
    }
    setFocusedIndex(nextIndexCount);
  };

  const searchByUserHandler = (creator) => {
    setCurrentCreator(creator.id);
    setCreatorList([]);
    setShowOption(false);
    setLoader(true);
    setInput(creator.username);
    // inputRef.current.blur();
    get(
      `${ApiURL.getPosts}?page=${1}&limit=${limit}&search=${creator.id
      }&category=${searchCategory}&postPage=${postPage}&postLimit=${postLimit}`
    ).then((response) => {
      setLoader(false);
      if (response.status) {
        setPostCount(response?.count);
        setPosts(
          response?.posts?.map((item) => {
            return { ...item, editComment: false };
          })
        );
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  const getMenuData = (fun) => {
    console.log("fun", fun);
  };

  return (
    <>
      <div className="container">
        <div className="bakwas row">
          <div className="container row">
            <Navbar></Navbar>
            <div className="row pt-3 d-block d-lg-none">
              <div className="right-head ms-auto d-flex justify-content-center">
                <div className="swip-btn me-1">
                  <figure>
                    <i className="ri-exchange-line"></i>
                  </figure>
                  <h4>Swap</h4>
                </div>
                <Menu getMenuData={getMenuData}></Menu>
              </div>
            </div>
            <div className="row pt-3 d-block d-lg-none">
              <div className="col-12 form-group input-with-btn">
                <input
                  type="text"
                  name="search"
                  value={input}
                  className="w-20 form-control bg-transparent form-control-in"
                  placeholder="Creator post search here..."
                  onChange={search}
                  ref={inputRef}
                />
                <button>
                  {<i class="ri-search-line" onClick={searchData}></i>}
                </button>
              </div>
              <ul class="nav nav-tabs mt-3 tabs-filter">
                <li class="nav-item">
                  <Link
                    className={
                      "all" == searchAttribute.toLowerCase()
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={() => searchAttr()}
                  >
                    All
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      "featured" === searchAttribute.toLowerCase()
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={() => searchAttr({ name: "FEATURED" })}
                  >
                    Featured
                  </Link>
                </li>
                {attributeData.map((attribute) => {
                  return (
                    <li class="nav-item">
                      <Link
                        className={
                          attribute?.name == searchAttribute
                            ? "nav-link active"
                            : "nav-link"
                        }
                        aria-current="page"
                        onClick={() => searchAttr(attribute)}
                      >
                        {attribute?.name}
                      </Link>
                    </li>
                  );
                })}
                <li class="nav-item">
                  <Link
                    className={
                      "subscribe" === searchAttribute.toLowerCase()
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={() => searchAttr({ name: "SUBSCRIBE" })}
                  >
                    Subscribed
                  </Link>
                </li>
              </ul>
              <div className="filter-hashtag mt-3">
                {categorySummaryData &&
                  categorySummaryData.length > 0 &&
                  categorySummaryData?.map((category) => {
                    return (
                      <button onClick={() => searchCat(category)}>
                        #{category?.name}
                      </button>
                    );
                  })}
              </div>
              <div className="form-group">
                <Multiselect
                  className="form-control"
                  options={category} // Options to display in the dropdown
                  ref={categoryRef}
                  displayValue="name"
                  onSelect={handleSelect}
                  onRemove={handleRemove}
                  selectedValues={listClicked}
                  closeOnSelect
                  customCloseIcon={"circle"}
                // singleSelect={true}
                />
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-sm-8 d-none d-lg-block">
                <div className="search-dropdown">
                  <div className="form-group bg-transparent mb-4 mt-2 mb-sm-0 mt-sm-0 d-flex align-items-stretch justify-content-center input-with-btn">
                    <input
                      type="text"
                      name="search"
                      value={input}
                      className="form-control form-control-in"
                      placeholder="Creator post search here..."
                      onChange={search}
                      onFocus={() => setShowOption(true)}
                      onKeyDown={handleArrowKey}
                    />
                    <button>
                      {<i class="ri-search-line" onClick={searchData}></i>}
                    </button>
                    <Loader visible={loader} />
                  </div>
                  {showOption &&
                    input.length > 0 &&
                    creatorList &&
                    creatorList.length > 0 && (
                      <SearchResult
                        focusedIndex={focusedIndex}
                        creatorList={creatorList}
                        searchByUserHandler={searchByUserHandler}
                      />
                    )}
                </div>
                <ul class="nav nav-tabs mt-3 tabs-filter">
                  <li class="nav-item">
                    <Link
                      className={
                        "all" == searchAttribute.toLowerCase()
                          ? "nav-link active"
                          : "nav-link"
                      }
                      aria-current="page"
                      onClick={() => searchAttr()}
                    >
                      All
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      className={
                        "featured" === searchAttribute.toLowerCase()
                          ? "nav-link active"
                          : "nav-link"
                      }
                      aria-current="page"
                      onClick={() => searchAttr({ name: "FEATURED" })}
                    >
                      Featured
                    </Link>
                  </li>
                  {attributeData.map((attribute) => {
                    return (
                      <li className="nav-item">
                        <Link
                          className={
                            attribute?.name == searchAttribute
                              ? "nav-link active"
                              : "nav-link"
                          }
                          aria-current="page"
                          onClick={() => searchAttr(attribute)}
                        >
                          {attribute?.name}
                        </Link>
                      </li>
                    );
                  })}
                  <li class="nav-item">
                    <Link
                      className={
                        "subscribe" === searchAttribute.toLowerCase()
                          ? "nav-link active"
                          : "nav-link"
                      }
                      aria-current="page"
                      onClick={() => searchAttr({ name: "SUBSCRIBE" })}
                    >
                      Subscribed
                    </Link>
                  </li>
                </ul>
                <div className="filter-hashtag mt-3">
                  {categorySummaryData &&
                    categorySummaryData.length > 0 &&
                    categorySummaryData?.map((category) => {
                      return (
                        <button onClick={() => searchCat(category)}>
                          #{category?.name}
                        </button>
                      );
                    })}
                </div>
                {
                  <div className="form-group">
                    <Multiselect
                      className="form-control"
                      options={category} // Options to display in the dropdown
                      ref={categoryRef}
                      displayValue="name"
                      onSelect={handleSelect}
                      onRemove={handleRemove}
                      // selectionLimit={1}
                      closeOnSelect
                      selectedValues={listClicked}
                    // singleSelect={true}
                    />
                  </div>
                }
              </div>

              <div className="col-sm-4 d-none d-lg-block">
                <div className="right-head ms-auto d-flex align-items-center justify-content-sm-end">
                  <div className="swip-btn me-3">
                    <figure>
                      <i className="ri-exchange-line"></i>
                    </figure>
                    <h4>Swap</h4>
                  </div>
                  <Menu myData={myData}></Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2 pt-sm-5">
          <div className="col-sm-8 left-dashboard">
            {posts.length > 0 ? (
              <PostList
                hasData={hasData}
                getPostData={getPostData}
                posts={posts}
                setPosts={setPosts}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPostPage={setPostPage}
                postPage={postPage}
                setPostLimit={setPostLimit}
                postLimit={postLimit}
                setPostCount={setPostCount}
                postCount={postCount}
                setCurrentCommentPage={setCurrentCommentPage}
                currentCommentPage={currentCommentPage}
                commentPaginationHandler={commentPaginationHandler}
                myData={myData}
                setMyData={setMyData}
                setSearchCategory={setSearchCategory}
                setListClicked={setListClicked}
              />
            ) : !loader ? (
              <p style={{ fontSize: "20px" }}>No post created yet</p>
            ) : null}
          </div>
          <Suggestions></Suggestions>
        </div>
      </div>
      <DialogBox
        open={open}
        setOpen={setOpen}
        setCategoryData={setCategoryData}
        categories={categoryData}
        searchCat={searchCat}
      ></DialogBox>
    </>
  );
}
