import React from 'react'
import { Link } from 'react-router-dom';
import DetailGallery from './detail-gallery';

const ImageGallary = ({ images, post }) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    let formattedArr = [...images];
    // if (images.length === 1) {
    //     return <>
    //         {
    //             images.map(image => <img alt="..." src={image} className="one-image" />)
    //         }
    //     </>
    // } else if (images.length === 2) {
    //     return <div className='two-image-gallary'>
    //         {
    //             images.map(image => <img alt="..." src={image} className="two-image" />)
    //         }
    //     </div>
    // } else if (images.length === 3) {
    //     const image = images.shift();
    //     return (
    //         <>
    //             {
    //                 <img alt="..." src={image} className="one-third-image" />
    //             }
    //             <div className='two-image-gallary'>
    //                 {
    //                     images.map(image => <img alt="..." src={image} className="two-image" />)
    //                 }
    //             </div>
    //         </>
    //     )
    // } else if (images.length === 4) {
    //     const twoImages = images.slice(0, 2)
    //     const oterTwoImages = images.slice(2, 4)
    //     return (
    //         <>
    //             <div className='two-image-gallary'>
    //                 {
    //                     twoImages.map(image => <img alt="..." src={image} className="two-image" />)
    //                 }
    //             </div>
    //             <div className='two-image-gallary'>
    //                 {
    //                     oterTwoImages.map(image => <img alt="..." src={image} className="two-image" />)
    //                 }
    //             </div>
    //         </>
    //     )
    // } else if (images.length > 4) {
    //     const backgroundImage = images[images.length - 1]

    //     const twoImages = images.slice(0, 2)
    //     const oterTwoImages = images.slice(2, 3)
    //     return (
    //         <>
    //             {/* <div className='two-image-gallary'>
    //                 {
    //                     twoImages.map(image => <img alt="..." src={image} className="two-image" />)
    //                 }
    //             </div>
    //             <div className='two-image-gallary'>
    //                 {
    //                     oterTwoImages.map(image => <img alt="..." src={image} className="two-image" />)
    //                 }

    //                 <div className='show-more-images' style={{ backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)),url(${backgroundImage})` }}>
    //                     <Link to={"/post-details/" + post.id}>
    //                         <i
    //                             className="fa fa-plus-circle"
    //                             aria-hidden="true"
    //                             style={{ color: 'white', fontSize: "100px" }}
    //                         ></i>
    //                     </Link>
    //                 </div>
    //             </div> */}

    //             <div className="row img-gallary-box img-item-3">
    //                 <div className="col-md-12">
    //                     <figure>
    //                         <img src="https://images.pexels.com/photos/6599062/pexels-photo-6599062.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" width="100%" />
    //                     </figure>
    //                 </div>
    //                 <div className="col-md-12">
    //                     <figure>
    //                         <img src="https://images.pexels.com/photos/6599062/pexels-photo-6599062.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" width="100%" />
    //                     </figure>
    //                 </div>
    //                 <div className="col-md-12">
    //                     <figure>
    //                         <img src="https://images.pexels.com/photos/6599062/pexels-photo-6599062.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" width="100%" />
    //                     </figure>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }
    if (formattedArr.length > 5) {
        formattedArr = formattedArr.slice(0, 5)
    }
    return (
        // <Link to={'/post-details/' + (post.id)}>
        <>
            <div className={images.length === 1 ? "row img-gallary-box img-item-1" : images.length === 2 ? "row img-gallary-box img-item-2" : images.length === 3 ? "row img-gallary-box img-item-3" : images.length === 4 ? "row img-gallary-box img-item-4" : "row img-gallary-box img-item-4-plus"}>
                {formattedArr?.map((image, index) => (
                    <div className="col-md-12">
                        <figure onClick={handleClickOpen}>
                            {
                                images.length > 5 && index === 4 && <span>+{images.length - 4}</span>
                            }
                            {
                                image.type.toLowerCase() === "image" ? <img src={image.url} width="100%" /> : (<video width={"100%"}>
                                    <source src={image.url}></source>
                                </video>)
                            }
                        </figure>
                    </div>
                ))}


            </div>
            <DetailGallery open={open} setOpen={setOpen} data={images}></DetailGallery>
        </>
        // </Link>
    )
}

export default ImageGallary