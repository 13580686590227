import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setCurrentUser } from "../../../app/slices/auth";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { get, patch, post } from "../../../services/services";
import { UpdateProfile } from "../../../utils/validation";
import ImagePreview from "../../commn/ImagePreview";
import ImageCrop from "../ImageCrop/ImageCrop";
import Loader from "../loader/loader";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Suggestions from "../suggestions/suggestions";
export default function Profile() {
  const { user } = useSelector((state) => state.auth);
  const [category, setCategory] = useState([]);
  const [imageBlob, setImageBlob] = useState("");
  const [openPicker, setOpenPicker] = useState(false);
  const { contract } = useSelector((state) => state.contract);
  const { account } = useSelector((state) => state.contract);
  const [imageUrl, setImageUrl] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  console.log("account", account, contract);
  const formState = {
    email: user.email,
    gender: user.gender,
    username: user.username,
    fullName: user.fullName,
    image: null,
    price: user.price,
  };

  useEffect(() => {
    getAttributes();
  }, []);
  const handleClose = () => {
    setLoading(false);
    setOpenPicker(false);
    setCurrentImage(null);
    setImageUrl(null);
  };

  const handleDoneClose = (croppedImageUrl) => {
    uploadImage();
    // setCurrentImage(null);
    setOpenPicker(false);
    setImageUrl(null);
  };

  const handleClickOpen = () => {
    setOpenPicker(true);
  };

  const uploadImage = (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    post(ApiURL.getUserImage, formData, 1).then((res) => {
      setCurrentImage(res.data);
      setLoading(false);
      if (res.status) {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const getAttributes = () => {
    get(`${ApiURL.getAttributes}?type=create`).then((res) => {
      if (res.status) {
        setCategory(res.data);
      }
    });
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    setLoading(true);
    uploadImage(e);
    // setOpenPicker(true);
  };

  return (
    <div className="container">
      <Navbar></Navbar>
      <div class="row pt-1">
        <div class="col-sm-8 d-none d-sm-block">
          <h4 class="ms-3">Profile</h4>
        </div>

        <div class="col-sm-4 d-none d-sm-block">
          <div class="right-head ms-auto d-flex align-items-center justify-content-sm-end">
            <div class="swip-btn me-3">
              <figure>
                <i class="ri-exchange-line"></i>
              </figure>
              <h4>Swap</h4>
            </div>
            <Menu></Menu>
          </div>
        </div>
      </div>
      <Loader visible={loading} />
      <h2 class="text-center d-block d-sm-none">Profile</h2>
      <div class="row pt-2 pt-sm-5 suggestions-hide">
        {formState && formState.fullName && (
          <div className="col-sm-8 d-block d-sm-block left-dashboard">
            <Formik
              initialValues={formState}
              validationSchema={UpdateProfile}
              onSubmit={async (values, { setErrors, setStatus, setSubmit }) => {
                setLoading(true);
                const username = values?.username?.trim();
                const fullName = values?.fullName?.trim();
                if (parseInt(values.price) !== user.price && window.ethereum) {
                  try {
                    console.log(contract[0]);
                    // await contract[0].methods.createPlan(values.price, "1").call()
                    const test = await contract[0].methods
                      .createPlan(values.price, "3")
                      .send({ from: account });
                    console.log("test", test);
                  } catch (error) {
                    toast.error(error.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                    return;
                  }
                } else if (!window.ethereum) {
                  toast.error("Please install meta mask ", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setLoading(false);
                  return;
                }
                if (username?.length === 0 || fullName?.length === 0) {
                  toast.error("Please enter valid inputs", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setLoading(false);
                  return;
                }
                if (
                  values?.username === user?.username &&
                  values?.fullName === user?.fullName &&
                  values?.gender === user?.gender &&
                  !currentImage &&
                  user?.role == "fan"
                ) {
                  setLoading(false);
                  return;
                } else if (
                  values?.username === user?.username &&
                  values?.fullName === user?.fullName &&
                  values?.gender === user?.gender &&
                  values?.price === user?.price &&
                  !currentImage &&
                  user?.role == "creator"
                ) {
                  setLoading(false);
                  return;
                } else {
                  const formData = {
                    username: values.username,
                    fullName: values.fullName,
                    image: currentImage,
                    gender: values.gender,
                    price: user.role == "creator" ? values.price : null,
                  };
                  patch(ApiURL.updateMe, formData).then((response) => {
                    setLoading(false);
                    if (response.status) {
                      toast.success(response?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                      get(ApiURL.findMe).then((response) => {
                        if (response.status) {
                          dispatch(setCurrentUser(response.user));
                        } else {
                          toast.error(response?.response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        }
                      });
                    } else {
                      toast.error(response?.response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                  });
                }
                values.image = null;
                setLoading(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} className="profile-p-form">
                  <div class="profile-photo">
                    <div class="profile-photo-in">
                      <figure>
                        {currentImage || user.image ? (
                          <ImagePreview
                            file={currentImage}
                            imageValue={baseURL + user.image}
                          />
                        ) : (
                          <img src="/frontend/images/default.jpg" alt="img" />
                        )}
                      </figure>
                      <div class="button-file">
                        <input
                          type="file"
                          id="image"
                          name="image"
                          onChange={(event) => {
                            onSelectFile(event);
                            // setFieldValue("image", event.target.files[0]);
                          }}
                          accept="image/png, image/jpeg, image/jpg"
                        />
                        <span>
                          <i class="ri-image-line"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  {openPicker && imageUrl && (
                    <ImageCrop
                      type="image"
                      file={imageUrl}
                      // setImage={setImage}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      open={openPicker}
                      setOpen={setOpenPicker}
                      handleDoneClose={handleDoneClose}
                      setImageBlob={setImageBlob}
                    />
                  )}

                  <div class="form-group mb-3">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      class="form-control form-control-in"
                      value={values.email}
                      onChange={handleChange}
                      disabled
                      style={{ color: "grey" }}
                    />
                  </div>
                  <div class="form-group mb-3">
                    <label>Gender</label>
                    <select
                      className="form-control form-control-in"
                      name="gender"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="gender"
                      style={
                        touched.gender && errors.gender
                          ? { border: "1px red solid", color: "white" }
                          : { color: "white" }
                      }
                      value={values.gender}
                    >
                      {category?.map((value) => {
                        return <option value={value?.id}>{value?.name}</option>;
                      })}
                    </select>
                    {touched.gender && errors.gender && (
                      <p
                        style={{ color: "red", margin: "5px 0" }}
                        error
                        id="standard-weight-helper-text-gender-login"
                      >
                        {errors.gender}
                      </p>
                    )}
                  </div>
                  <div class="form-group mb-3">
                    <label>User name</label>
                    <input
                      type="text"
                      class="form-control form-control-in"
                      name="username"
                      value={values.username}
                      placeholder="User name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="username"
                      readOnly
                      disabled
                      style={
                        touched.username && errors.username
                          ? { border: "1px red solid", color: "white" }
                          : { color: "white" }
                      }
                    />
                    {touched.username && errors.username && (
                      <p
                        style={{ color: "red", margin: "5px 0" }}
                        error
                        id="standard-weight-helper-text-username-login"
                      >
                        {errors.username}
                      </p>
                    )}
                  </div>
                  <div class="form-group mb-3">
                    <label>Full name</label>
                    <input
                      type="text"
                      class="form-control form-control-in"
                      name="fullName"
                      value={values.fullName}
                      placeholder="Full name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullName"
                      style={
                        touched.fullName && errors.fullName
                          ? { border: "1px red solid", color: "white" }
                          : { color: "white" }
                      }
                    />
                    {touched.fullName && errors.fullName && (
                      <p
                        style={{ color: "red", margin: "5px 0" }}
                        error
                        id="standard-weight-helper-text-fullName-login"
                      >
                        {errors.fullName}
                      </p>
                    )}
                  </div>
                  {user?.role == "creator" ? (
                    <div class="form-group mb-3">
                      <label>Subscription Price(Per month)</label>
                      <input
                        type="text"
                        class="form-control form-control-in"
                        name="price"
                        value={values.price}
                        placeholder="Subscription Price"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="price"
                        style={
                          touched.price && errors.price
                            ? { border: "1px red solid", color: "white" }
                            : { color: "white" }
                        }
                      />
                      {touched.price && errors.price && (
                        <p
                          style={{ color: "red", margin: "5px 0" }}
                          error
                          id="standard-weight-helper-text-price-login"
                        >
                          {errors.fullName}
                        </p>
                      )}
                    </div>
                  ) : null}
                  {loading ? (
                    <button
                      type="submit"
                      disabled={loading}
                      class="btn btn-p w-100 buttonLoad"
                    >
                      <i className="fa fa-spinner fa-spin"></i>
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-p w-100">
                      Update profile
                    </button>
                  )}
                </form>
              )}
            </Formik>
          </div>
        )}
        <Suggestions></Suggestions>
      </div>
    </div>
  );
}
