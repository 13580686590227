import React from "react";
import ReactDOM from "react-dom/client";
import Web3 from "web3";
// import './index.css';
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import "react-image-crop/dist/ReactCrop.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
