import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { get, post } from "../../../services/services";
import ImagePreview from "../../commn/ImagePreview";
import VideoPreview from "../../commn/VideoPreview";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Suggestions from "../suggestions/suggestions";
// import { CKEditor } from 'ckeditor4-react';
import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from "react-redux";
import Tiny from "../tinyEditor/tiny";
import Multiselect from "multiselect-react-dropdown";
import EmojiPicker from "emoji-picker-react";
import ImageCrop from "../ImageCrop/ImageCrop";
import CircularStatic from "../progress/progressBar";
import Loader from "../loader/loader";
import { MentionsInput, Mention } from 'react-mentions'
export default function CreatePost() {
  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState("");
  const [video, setVideo] = useState([]);
  const [image, setImage] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [vidProgress, setVidProgress] = useState(0);
  const [imgProgress, setImgProgress] = useState(0);
  const [privacy, setPrivacy] = useState("public");
  const categoryRef = useRef();
  const videoInputRef = useRef();
  const imageInputRef = useRef();
  const navigate = useNavigate();
  const tinyRef = useRef();
  const txtRef = useRef();
  const formRef = useRef();
  const picker = useRef();
  const token = localStorage.getItem('token')
  let replyEditor = null;
  const [currentImage, setCurrentImage] = useState("");
  const [openPicker, setOpenPicker] = useState(false);
  const [imageBlob, setImageBlob] = useState("");
  const [croppedImage, setCroppedImage] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [storeOriginalImage, setStoreOriginalImage] = useState([]);
  const [description, setDescription] = useState("");
  const [creators, setCreators] = useState([]);
  const users = [
    {
      id: 'isaac',
      display: 'Isaac Newton',
    },
    {
      id: 'sam',
      display: 'Sam Victor',
    },
    {
      id: 'emma',
      display: 'emmanuel@nobody.com',
    },
  ];


  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };

  }, []);

  const handleClickOutside = (event) => {
    if (picker.current && !picker.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const imageCloseHandler = (index) => {
    setCurrentImage("");
    setImageUrl(null);
    setImageBlob("")
    const _images = [];
    const _blob = [];
    const _originalImg = [];
    for (const _index in image) {
      if (Number(_index) === Number(index)) {
        continue;
      } else {
        _images.push(image[_index])
        _originalImg.push(storeOriginalImage[_index])
        _blob.push(croppedImage[_index])
      }
    }
    setImage([..._images])
    setStoreOriginalImage([..._originalImg])
    setCroppedImage([..._blob])
  }

  const videoCloseHandler = (index) => {
    const formattedVids = []
    for (const vidIndex in video) {
      if (Number(vidIndex) === Number(index)) {
        continue
      } else {
        formattedVids.push(video[vidIndex])
      }
    }
    setVideo([...formattedVids])
  }

  const getCategory = () => {
    get(ApiURL.getCategory).then((res) => {
      if (res.status) {
        let data = [];
        res?.data?.map((value) => {
          data.push({ name: value?.name, id: value?.id })
        })
        setCategory(data);
      }
    });
  }

  const handleRemove = (e) => {
    setSelectCategory(e)
  }

  useEffect(() => {
    getCategory()
    getCreator();
  }, [])

  const handleFormSubmit = (values, resetForm) => {
    setLoading(true);
    if (description.length > 0 && description.trim().length > 0) {
      let tempData = selectCategory.map((v) => {
        return Number(v.id)
      })
      if (tempData.length == 0) {
        setLoading(false);
        return toast.error("Please select category", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }

      let obj = {
        description: description,
        category_id: JSON.stringify(tempData),
        privacy: privacy,
        video: JSON.stringify(video),
        image: JSON.stringify(image),
        originalImage: JSON.stringify(storeOriginalImage),
      }
      post(ApiURL.createPost, obj, 1).then((response) => {
        if (response.status) {
          setLoading(false);
          toast.success(response?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setImage([])
          setVideo([])
          setOriginalImage([])
          navigate("../dashboard", { replace: true });
        } else {
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setLoading(false);
        }
      });
    } else {
      toast.error("Please enter valid description", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      setLoading(false);
    }
  };

  const getCreator = async () => {
    get(ApiURL.getCreatorList + "?search=&attribute=All&page=1&limit=15").then((res) => {

      let arr = [];
      console.log("aaaayyyaa", res.creators)
      for (let data of res.creators) {
        console.log("data", data);
        let obj = {
          id: data?.username,
          display: data?.username
        }
        arr.push(obj);
      }
      setCreators(arr)
    });
  }

  const handleSelect = (e) => {
    setSelectCategory(e)
  }

  const videoUpload = (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    post(ApiURL.chatVideo, formData, 1, {
      onUploadProgress: progressEvent => {
        setVidProgress((progressEvent.loaded / progressEvent.total) * 100);
      }
    }).then((res) => {
      setVideo(prevState => [...prevState, res.link])
    })
  }

  const imageUpload = (e) => {
    const formData = new FormData();
    // formData.append('file', imageBlob);
    // formData.append('originalImage', originalImage)
    formData.append('file', e.target.files[0]);
    // formData.append('originalImage', e.target.files[0])
    post(ApiURL.postImage, formData, 1, {
      onUploadProgress: progressEvent => {
        setImgProgress((progressEvent.loaded / progressEvent.total) * 100);
      }
    }).then((res) => {
      setImage(prevState => [...prevState, res.thumb])
      setStoreOriginalImage(prevState => [...prevState, res.original])
      setLoading(false)
    })
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setOriginalImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    setLoading(true)
    // setOpenPicker(true);
    imageUpload(e);
  };

  const handleClickOpen = () => {
    setOpenPicker(true);
  };

  const handleClose = () => {
    setLoading(false)
    setOpenPicker(false);
    setCurrentImage(null);
    setImageUrl(null)
  };

  const emojiHandler = (e) => {
    let localData = description;
    setDescription(localData += e.emoji)
    // formRef.current.setFieldValue("description",);
  }
  const handleChangeData = (e) => {
    setDescription(e.target.value)
  }

  const handleDoneClose = (croppedImageUrl) => {
    // setCroppedImage(prevState => [...prevState, croppedImageUrl]);
    // imageUpload()
    // setCurrentImage(null);
    // setOpenPicker(false);
    // setImageUrl(null);
  };

  return (
    <div className="container">
      <Navbar></Navbar>
      <div className="">
        <Loader visible={loading} />
        <div className="row home-landing d-flex">
          <div className="col-md-8 mx-auto left-dashboard">
            <div className="profile-page mb-3">
              <h4 className="">Create post</h4>
              <p>Have something to share with the community? </p>
              <Formik
                innerRef={formRef}
                initialValues={{ description: "", image: "", video: "" }}
                onSubmit={(
                  values,
                  {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm,
                    setFieldValue,
                  }
                ) => handleFormSubmit(values, resetForm)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} className="createpost-in mt-3">
                    <div className="form-group">
                      <MentionsInput value={description}
                        ref={txtRef}
                        style={{ lineHeight: "normal", height: "200px" }}
                        className="form-control form-control-in"
                        onChange={handleChangeData}>
                        <Mention
                          trigger="@"
                          data={creators}
                        />
                        {/* <Mention
                          trigger="#"
                          data={this.requestTag}
                          renderSuggestion={this.renderTagSuggestion}
                        /> */}
                      </MentionsInput>
                    </div>
                    <div className="button-c-post d-flex align-items-center justify-item-center p-2">
                      <div className="button-c-p-g" style={{ width: "100%" }}>
                        <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                          {image.length > 0 && image && image.length > 0 && image.map((imag, index) => (
                            <div className="image-upload-wrap" >
                              <button onClick={() => imageCloseHandler(index)} type="button" className="iocn-btn"><i className="ri-close-line"></i></button>
                              <ImagePreview imageValue={imag} />
                            </div>
                          )
                          )}

                          {video.length > 0 && (
                            <div className="video-upload-wrap" style={{ width: "100%" }}>
                              {
                                video.map((_video, index) => (
                                  <><button onClick={() => videoCloseHandler(index)} type="button" className="iocn-btn"><i className="ri-close-line"></i></button>
                                    <VideoPreview videoValue={_video} /></>
                                ))}
                            </div>
                          )}
                          {
                            imgProgress > 0 && imgProgress < 100 && (
                              <div className="upload-preview-box">
                                <CircularStatic progress={imgProgress} />
                              </div>
                            )
                          }
                          {
                            vidProgress > 0 && vidProgress < 100 && (
                              <div className="upload-preview-box">
                                <CircularStatic progress={vidProgress} />
                              </div>
                            )
                          }
                        </div>
                        <div className="create-action">
                          <>  <button
                            className="btn"
                            type="button"
                            onClick={() => imageInputRef.current.click()}
                          >
                            <i className="ri-image-fill"></i> <span>Image</span>
                          </button>
                            <input
                              type="file"
                              id="image"
                              name="image"
                              onChange={onSelectFile}
                              hidden
                              ref={imageInputRef}
                              accept="image/*"
                            />
                          </>
                          {openPicker && imageUrl && (
                            <ImageCrop
                              type="image"
                              file={imageUrl}
                              // setImage={setImage}
                              handleClickOpen={handleClickOpen}
                              handleClose={handleClose}
                              open={openPicker}
                              setOpen={setOpenPicker}
                              handleDoneClose={handleDoneClose}
                              setImageBlob={setImageBlob}
                            />
                          )}
                          <><button
                            className="btn"
                            type="button"
                            onClick={() => videoInputRef.current.click()}
                          >
                            <i className="ri-video-add-line"></i>{" "}
                            <span>Video</span>
                          </button>
                            <input
                              type="file"
                              id="video"
                              name="video"
                              onChange={(event) => {
                                videoUpload(event)
                              }}
                              hidden
                              ref={videoInputRef}
                              accept="video/*"
                            /></>
                          <button type="button" style={{ position: "relative" }} className="btn" onClick={() => setOpen(!open)}>
                            <i class="ri-emotion-line"></i>
                            <span>Emoji</span>
                          </button>
                          {
                            open && <div ref={picker} ><EmojiPicker emojiStyle={{ position: "absolute" }} onEmojiClick={emojiHandler}
                              theme="dark" /></div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label>Category</label>
                      <Multiselect className="form-control"
                        options={category} // Options to display in the dropdown
                        ref={categoryRef}
                        displayValue="name"
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>Type</label>
                      <select name="privacy" className="form-control" onChange={(e) => setPrivacy(e.target.value)}>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                      </select>
                    </div>
                    <div className="create-post-btn ms-auto" style={{ marginTop: "10px" }}>
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-p"
                      >
                        Post
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <Suggestions></Suggestions>
        </div>
      </div>
    </div>
  );
}

