import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token"),
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
  },
  reducers: {
    setToken: (state, action) => {
      localStorage.setItem("token", action.payload.token);
      state.token = action.payload.token;
    },
    setCurrentUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload))
    },
    setType: (state, action) => {
      state.userType = action.payload;
    },
    setLogout: (state, action) => {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      state.token = null;
      state.user = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setType, setCurrentUser, setLogout } = auth.actions;

export default auth.reducer;
