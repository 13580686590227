import * as Yup from "yup";

export const SignUpSchema = Yup.object().shape({
  userName: Yup.string()
    .max(20, "User Name can be 20 characters")
    .required("Username is required"),
  fullName: Yup.string()
    .max(20, "Full Name can be 20 characters")
    .required("Full name is required"),
  gender: Yup.string().required("Gender is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  email: Yup.string().email().required("Email is required"),
  age: Yup.bool().oneOf([true], "Field must be checked"),
});

export const UpdateProfile = Yup.object().shape({
  username: Yup.string()
    .max(20, "User Name can be 20 characters")
    .required("Username is required"),
  fullName: Yup.string()
    .max(20, "Full Name can be 20 characters")
    .required("Full name is required"),
  gender: Yup.string().required("Gender is required"),
});

export const LogiSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  email: Yup.string().required("Email/Username is required"),
});

export const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});
export const ForgetPasswordOtp = Yup.object().shape({
  otp: Yup.string()
    .required("Otp is required")
    .min(6, "Otp is too short - should be 6 minimum"),
});
export const NewPasswordScheema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confrim Password is required")
    .min(6, "Confrim is too short - should be 6 minimum")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});


export const ChangePasswordScheema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, "Old Password can be 8 characters")
    .required("Old Password is required"),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("New Password is required"),
  confirmPassword: Yup.string().required("Confirm Password is required").oneOf([Yup.ref("newPassword"), null], "Confirm Password must match with new password "),
});
