import { Form, Formik } from "formik";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL } from "../../services/apiConstants";
import { post } from "../../services/services";
import { ForgetPasswordOtp } from "../../utils/validation";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function NewPasswordOtp() {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const email = query.get("user");
  const navigate = useNavigate();

  const submitHandler = async (values) => {
    setLoading(true);
    const res = await post(ApiURL.verifyOTP, { otp: values.otp });
    post(ApiURL.verifyOTP, { otp: values.otp }).then((response) => {
      if (response.status) {
        toast.success(response?.response?.data?.message || response?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        const token = res && res.status === true && res.token;
        navigate(`../reset-password/${token}`, { replace: true });
        setLoading(false);
      } else {
        toast.error(response?.response?.data?.message || response?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        setLoading(false);
      }
    });
  };

  const resendOTP = () => {
    setLoading(true);
    post(ApiURL.forget, { email }).then((res) => {
      if (res.status) {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      } else {
        toast.error(res?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };

  return (
    <div className="signup-wrap">
      <div className="signup-box ">
        <div className="signup-left my-auto py-5">
          <div className="signup-form mx-auto">
            <div className="signup-logo">
              <Link to={"/"}>
                <img src="frontend/images/logo.svg" alt="" />
              </Link>
              <h4>Forgot in to your account!</h4>
            </div>
            <Formik
              initialValues={{ otp: "" }}
              validationSchema={ForgetPasswordOtp}
              onSubmit={(values, { setSubmitting }) => {
                submitHandler(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form>
                  <div className="signup-form-in">
                    <div className="form-group mb-3">
                      <OtpInput
                        value={values.otp}
                        onChange={(value) => {
                          setFieldValue("otp", value);
                        }}
                        numInputs={6}
                        inputStyle={{ width: "50px", height: "50px" }}
                        containerStyle={{ justifyContent: "space-between" }}
                        hasErrored={errors.otp}
                        errorStyle={{ color: "red" }}
                        name="otp"
                        isInputNum={true}
                      />
                      {touched.otp && errors.otp && (
                        <p
                          style={{ color: "red", margin: "5px 0" }}
                          id="standard-weight-helper-text-otp-login"
                        >
                          {errors.otp}
                        </p>
                      )}
                      {/* <div className='text text-danger' style={{ marginLeft: 10 }}> {errors.otp && touched.otp && errors.otp}</div> */}
                    </div>

                    {loading ? (
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-p w-45 w-sm-45"
                      >
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-p w-45 w-sm-45">
                        Submit
                      </button>
                    )}

                    {loading ? (
                      <button
                        type="button"
                        disabled={loading}
                        className="btn btn-p w-45 w-sm-45"
                      >
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={resendOTP}
                        className="btn btn-p w-45 w-sm-45"
                        style={{ marginLeft: "5px" }}
                      >
                        Resend
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="signup-right">
          <figure>
            <img src="/frontend/images/img1.jpg" alt="img" />
            {/* <img src="/frontend/images/image1.jpg" /> */}
          </figure>
        </div>
      </div>
    </div>
  );
}
