import { createSlice } from "@reduxjs/toolkit";

export const tokenSlice = createSlice({
  name: "token",
  initialState: {
    token: localStorage.getItem("token"),
    user: localStorage.getItem("user"),
  },
  reducers: {
    setToken: (state, action) => {
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", action.payload.user);
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    setType: (state, action) => {
      console.log("action", action.payload);
      state.userType = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setType } = tokenSlice.actions;

export default tokenSlice.reducer;
