import React, { useState } from "react";
import CircularStatic from "../progress/progressBar";
const ChatVideoPreview = ({ file, vidProgress }) => {
  const [preview, setPreview] = useState(null);
  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }
  return (
    <>
      {console.log("vidProgress", vidProgress)}
      {vidProgress > 0 && vidProgress < 100 && (
        <div className="upload-preview-box">
          <CircularStatic progress={vidProgress} />
        </div>
      )}
      <video src={preview}>Your browser does not support the video tag.</video>
    </>
  );
};

export default ChatVideoPreview;
