import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TokenModal from "./tokenModal";
import { setCuminuToken } from "../../../app/slices/contractSlice";
export default function Menu({ myData }) {
  const { contract, account, cuminuToken } = useSelector(
    (state) => state.contract
  );
  const dispatch = useDispatch();
  const [myToken, setMyToken] = useState(0);
  const [newValueToken, setNewValueToken] = useState(0);
  const [open, setOpen] = useState(false);
  const txtRef = useRef();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getToken();
  }, [account, contract, myData, newValueToken]);
  const getToken = async () => {
    if (contract?.length > 0) {
      let Token = await contract[1]?.methods.balanceOf(account)?.call();
      dispatch(setCuminuToken({ cuminuToken: Token }));
      setMyToken(Token);
    }
  };
  return (
    <>
      <div className="available-tokens dropdown">
        <div
          className="available-tokens-drop"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <figure>
            <img src="frontend/images/logo-ic.png" />
          </figure>
          <figcaption>
            <h4>{cuminuToken}</h4>
            <span>Available tokens</span>
          </figcaption>
          <div className="down-token">
            <i className="ri-arrow-down-s-line"></i>
          </div>
        </div>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <Link className="dropdown-item" onClick={handleOpen}>
              <i className="ri-add-circle-line"></i> Add token
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/change-password-account"}>
              <i className="ri-lock-2-line"></i> Change password
            </Link>
          </li>
        </ul>
      </div>
      <TokenModal
        handleOpen={handleOpen}
        setOpen={setOpen}
        open={open}
        handleClose={handleClose}
        newValueToken={newValueToken}
        setNewValueToken={setNewValueToken}
      ></TokenModal>{" "}
    </>
  );
}
