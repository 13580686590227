import React from "react";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Suggestions from "../suggestions/suggestions";

export default function Earning() {
  return (
    <div className="site-wraper">
      <Navbar></Navbar>
      <div className="site-inner">
        <div className="home-landing d-flex">
          <div className="left-part">
            <Sidebar></Sidebar>
          </div>
          <div className="mid-part mx-auto">
            <div className="earnings-list">
              <h4 className="mb-3">Earnings</h4>

              <div className="total-earnings-box">
                <h6>Total earnings</h6>
                <h2>$215.00</h2>
              </div>
              <div className="earnings-list-li">
                <ul>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="post-header d-flex align-items-center mb-1 w-100">
                      <div className="profile-sec d-flex align-items-center">
                        <div className="profile-img me-3">
                          <figure>
                            <a href="#">
                              <img src="frontend/images/user2.png" />
                            </a>
                          </figure>
                        </div>
                        <div className="profile-info">
                          <h4>
                            <a href="#">Gregory Bell</a>
                          </h4>
                          <span>4 months ago</span>
                        </div>
                      </div>
                      <div className="ms-auto">
                        <h5>$41.00</h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Suggestions></Suggestions>
        </div>
      </div>
    </div>
  );
}
