import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { get } from "../../../services/services";
import Loader from "../loader/loader";
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Suggestions from "../suggestions/suggestions";
import Moment from "moment";
import Pagination from "react-mui-pagination";

export default function OurFans() {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setMyPage] = useState(1);
  const [subscribeUser, setSubscribeUser] = useState([]);
  const setPage = (e, p) => {
    setMyPage(p);
  };
  const subscribeUsers = () => {
    setLoading(true);
    get(ApiURL.ourFans + `?limit=${15}&page=${page}`).then((res) => {
      setSubscribeUser(res?.data?.rows);
      setPageCount(res?.data?.count);
      setLoading(false);
    });
  };
  useEffect(() => {
    subscribeUsers();
  }, []);
  return (
    <div className="container">
      <Navbar></Navbar>
      <div class="row pt-1">
        <div class="col-sm-8 d-none d-sm-block">
          <h4 class="ms-3">Our Fans</h4>
        </div>
        <div class="col-sm-4 d-none d-sm-block">
          <div class="right-head ms-auto d-flex align-items-center justify-content-sm-end">
            <div class="swip-btn me-3">
              <figure>
                <i class="ri-exchange-line"></i>
              </figure>
              <h4>Swap</h4>
            </div>
            <Menu></Menu>
          </div>
        </div>
      </div>
      <Loader visible={loading} />
      <h2 class="text-center d-block d-sm-none">Our Fans</h2>
      <div class="row pt-2 pt-sm-5 suggestions-hide">
        <div className="col-sm-8 d-block d-sm-block left-dashboard">
          <div className="table-box">
            <table className="table table-dark">
              <tr>
                <th>SR</th>
                <th>User</th>
                <th>Price</th>
                <th>Date</th>
              </tr>
              {subscribeUser &&
                subscribeUser.map((user, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <div className="table-user-box">
                          <figure>
                            <img src={baseURL + user?.ourFans?.image} />
                          </figure>
                          <figcaption>{user?.ourFans?.username}</figcaption>
                        </div>
                      </td>
                      <td>{user?.subscribe_price}</td>
                      <td>
                        {Moment(user?.subscribe_date).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  );
                })}
              {subscribeUser?.length == 0 && <tr>No record found</tr>}
            </table>
            {pageCount > 10 && (
              <Pagination
                page={page}
                setPage={setPage}
                perPage={10}
                total={pageCount}
                activeLinkColor="secondary"
              />
            )}
          </div>
        </div>
        <Suggestions></Suggestions>
      </div>
    </div>
  );
}
