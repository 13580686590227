import React, { Component, useEffect, useState } from "react";
import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LogiSchema } from "../../utils/validation";
import { ApiURL } from "../../services/apiConstants";
import { toast } from "react-toastify";
import { patch, post } from "../../services/services";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setCurrentUser } from "../../app/slices/auth";
import VerifyAccount from "./verifyAccount";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verifyLink, setVerifyLink] = useState(0);
  const [verifyUser, setVerifyUser] = useState(null);
  const [open, setOpen] = useState(false);
  const query = useQuery();
  const verifyToken = query.get("verify");

  const loginTapped = async (values) => {
    try {
      setVerifyUser(values.email)
      post(ApiURL.login, values).then((response) => {
        if (response.status) {
          navigate("/dashboard");
          toast.success(response?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          dispatch(setToken({ token: response.data.token }));
          dispatch(setCurrentUser({ user: response.data.user }));
          setLoading(false);
        } else {
          setVerifyLink(response?.response?.data?.verifyAccount)
          toast.error(response.response?.data?.message || response?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log('error', error.message())
    }

  };

  const sendLinkVerifyAccount = (email) => {
    let obj = {
      email: verifyUser
    }
    post(ApiURL.verifyAccountLink, obj).then((res) => {
      if (res.status) {
        setVerifyLink(res.verifyAccount)
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        setOpen(false)
      } else {
        toast.error(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    })
  }


  useEffect(() => {
    if (verifyToken) {
      setLoading(true);
      patch(ApiURL.verifyAccount, { verifyToken }).then((response) => {
        if (response.status) {
          toast.success(
            response?.response?.data?.message || response?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } else {
          setVerifyUser(response.data.email)
          toast.error(response?.response?.data?.message || response?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      setLoading(false);
    }
  }, [verifyToken]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LogiSchema}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          loginTapped(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form>
            <div
              class="jumbotron align-items-sm-center"
              style={{ display: "flex", minHeight: "100%", minHeight: "100vh" }}
            >
              <div className="container">
                <div className="row pt-5 pt-sm-0">
                  <div className="col-12 col-sm-8">
                    <div className="mx-auto">
                      <div className="signup-logo text-center">
                        <Link to={"/"}>
                          <img src="/frontend/images/logo.svg" alt="img" />
                        </Link>
                        <h4>Login in to your account</h4>
                      </div>
                      <div className="signup-form-in">
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder="Email/Username"
                            id="email"
                            onChange={handleChange}
                            style={
                              touched.email && errors.email
                                ? { border: "1px red solid", color: "white" }
                                : { color: "white" }
                            }
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          {touched.email && errors.email && (
                            <p
                              style={{ color: "red", margin: "5px 0" }}
                              id="standard-weight-helper-text-email-login"
                            >
                              {errors.email}
                            </p>
                          )}
                          <div
                            className="text text-danger"
                            style={{ marginLeft: "10px" }}
                          >
                            {" "}
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="password"
                            name="password"
                            id="password"
                            onChange={handleChange}
                            style={
                              touched.password && errors.password
                                ? { border: "1px red solid", color: "white" }
                                : { color: "white" }
                            }
                            onBlur={handleBlur}
                            value={values.password}
                            className="form-control"
                            placeholder="Password"
                          />
                          {touched.password && errors.password && (
                            <p
                              style={{ color: "red", margin: "5px 0" }}
                              id="standard-weight-helper-text-password-login"
                            >
                              {errors.password}
                            </p>
                          )}
                          <div
                            className="text text-danger"
                            style={{ marginLeft: "10px" }}
                          >
                            {" "}
                          </div>
                        </div>
                        <div className="forgot-pass mb-3">
                          <Link to={"/forget"}>Forgot password?</Link>
                        </div>
                        <div className="forgot-pass mb-3">
                          {verifyLink == 1 && <a href="#" onClick={handleClickOpen}>Verify account?</a>}
                          <VerifyAccount
                            open={open}
                            setOpen={setOpen}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            sendLinkVerifyAccount={sendLinkVerifyAccount}
                            values={values}
                          >
                          </VerifyAccount>
                        </div>
                        {loading ? (
                          <button
                            type="submit"
                            disabled={loading}
                            class="btn btn-p w-100 w-sm-50"
                          >
                            <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        ) : (
                          <button type="submit" class="btn btn-p w-100 w-sm-50">
                            Login
                          </button>
                        )}
                        <div className="login-already mt-2">
                          <p>
                            Are you a new user? <Link to={"/"}>Signup</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 d-none d-sm-block signup-img-box">
                    <figure className="d-flex">
                      <img src="/frontend/images/img2.jpg" alt="img" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;
