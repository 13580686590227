import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import { setCuminuToken } from "../../../app/slices/contractSlice";
import { useDispatch, useSelector } from 'react-redux';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#1e1e1e',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TokenModal({ setOpen, open, handleOpen, setNewValueToken, newValueToken }) {
    const { contract, account, cuminuToken } = useSelector(state => state.contract);
    const [description, setDescription] = React.useState("");
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);
    const handleChange = (e) => {
        setDescription(e.target.value)
    }

    const addToken = async () => {
        if (Number(description) < 0 || description.trim().length <= 0) {
            return toast.error("Please enter vaild number", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        }
        const test = await contract[1].methods.buyToken(Number(description)).send({
            from: account,
            value: Number(description) * (10 ** 18),
            gas: 2100000,
            gasPrice: 8000000000,
        });
        if (test.status) {
            handleClose();
            setDescription("")
            setNewValueToken(newValueToken + 1)
            dispatch(setCuminuToken({ cuminuToken: Number(cuminuToken) + Number(description) }))
            toast.success("Token added successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
        }
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Token
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="form-group mt-3">
                            <label>Token</label>
                            <input
                                type="number1"
                                className='form-control form-control-in'
                                name="token"
                                placeholder="Enter number of token"
                                onChange={handleChange}
                                id="token"
                                value={description}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            <button type="button" id='closeModal' className="btn btn-secondary mt-3 " onClick={() => handleClose()} >Close</button>
                            <button type="button" className="btn btn-p mt-3 ms-2" onClick={() => addToken()}>Submit</button>
                        </div>

                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
