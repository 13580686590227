import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../navbar/navbar";
import { get, post } from "../../../services/services";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Chat from "./chat";
import Loader from "../loader/loader";
import Subscribe from "../basic/subscribe";
import Menu from "../menu/menu";

export default function MessageDetails() {
  const [loader, setLoader] = useState(true);
  const [chat, setChat] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentReceiver, setCurrentReceiver] = useState({});
  const { receiverId } = useParams();
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.socket);
  const [videoFile, setVideoFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imgProgress, setImgProgress] = useState(0);
  const [vidProgress, setVidProgress] = useState(0);
  const [editing, setEditing] = useState(false);
  const [editChat, setEditChat] = useState({});

  useEffect(() => {
    get(`${ApiURL.getUser}/${receiverId}`).then((response) => {
      if (response.status) {
        setCurrentReceiver(response.user);
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  }, [receiverId]);

  const handleImage = (e) => {
    const formData = new FormData();
    setImageFile(e.target.files[0]);
    formData.append("file", e.target.files[0]);
    post(`${ApiURL.imageUpload}`, formData, 1, {
      onUploadProgress: (progressEvent) => {
        setImgProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    }).then((response) => {
      setImage(response?.link);
    });
  };
  const handleVideo = (e) => {
    const formData = new FormData();
    setVideoFile(e.target.files[0]);
    formData.append("file", e.target.files[0]);
    post(`${ApiURL.chatVideo}`, formData, 1, {
      onUploadProgress: (progressEvent) => {
        setVidProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    }).then((response) => {
      setVideo(response.link);
    });
  };
  const deleteChatHandler = (message) => {
    socket.emit("deleteChat", { chatId: message.id });
  };
  const editChatHandler = (message) => {
    setEditing(true);
    setCurrentMessage(message.body);
    setEditChat(message);
  };

  useEffect(() => {
    return () => {
      socket?.on("disconnect", function () {
        console.log("user disconnected");
      });
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.once("newMessageResponse", ({ newMessage }) => {
        if (
          newMessage &&
          user &&
          user.id &&
          currentReceiver &&
          currentReceiver?.id
        ) {
          let newData = [];
          if (
            (newMessage &&
              currentReceiver &&
              currentReceiver.id &&
              newMessage.reciver_id &&
              newMessage.reciver_id.toString() ===
                currentReceiver.id.toString() &&
              newMessage.sender_id.toString() === user.id.toString()) ||
            (newMessage.sender_id.toString() ===
              currentReceiver.id.toString() &&
              newMessage.reciver_id.toString() === user.id.toString())
          ) {
            newData = [...chat, newMessage];
          } else {
            newData = [...chat];
          }
          setChat(newData);
        }
      });
    }
    return () => socket?.off("newMessageResponse");
  }, [socket, currentReceiver, user, chat, setChat]);

  useEffect(() => {
    if (socket) {
      socket.on("deletedChat", ({ deletedChat }) => {
        if (chat.length > 0) {
          const chatCopy = [...chat];
          const newChat = chatCopy.filter(
            (_chat) => _chat.id !== deletedChat.id
          );
          setChat([...newChat]);
        }
      });
    }
  }, [socket, chat]);

  useEffect(() => {
    setLoader(true);
    get(`${ApiURL.getChat}/${receiverId}`).then((response) => {
      setLoader(false);
      if (response.status) {
        setChat(response.data);
      } else {
        setLoader(false);
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  }, [receiverId]);

  useEffect(() => {
    if (socket) {
      socket.on("editedChat", ({ editedChat }) => {
        console.log(editedChat);
        if (chat.length > 0) {
          const chatCopy = [...chat];
          const newChat = [];
          for (const _chat of chatCopy) {
            if (_chat.id === editedChat.id) {
              _chat.body = editedChat.body;
            }
            newChat.push(_chat);
          }
          setChat([...newChat]);
        }
      });
    }
  }, [socket, chat]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (editing) {
      socket.emit("editChat", {
        chatId: editChat.id,
        body: currentMessage,
      });
    } else {
      if (currentMessage.trim().length > 0 || image || video) {
        console.log("currentMessage", currentMessage);
        socket.emit("sendMessage", {
          senderId: user.id,
          receiverId,
          message: currentMessage,
          attechment: image?.trim()?.length > 0 ? image : null,
          video: video?.trim()?.length > 0 ? video : null,
        });
      } else {
        return toast.error("Please write a message", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    }
    setEditChat();
    setCurrentMessage("");
    setEditing(false);
    setImage(null);
    setVideo(null);
    setImageFile("");
    setVideoFile("");
  };

  return (
    <>
      <div className="container">
        <Navbar></Navbar>
        <div className="row pt-1">
          <div className="col-sm-5 d-none d-sm-block">
            <h4 className="ms-3">Messages</h4>
          </div>
          <div className="col-sm-7 d-none d-sm-block">
            <div className="right-head ms-auto d-flex align-items-center justify-content-sm-end">
              <div className="swip-btn me-3">
                <figure>
                  <i className="ri-exchange-line"></i>
                </figure>
                <h4>Swap</h4>
              </div>
              <Menu></Menu>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Link
              to={"/message"}
              className="btn w-100 text-light d-flex"
              state={{ id: receiverId }}
              style={{
                backgroundColor: "#f91381",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i className="ri-skip-back-line me-3"></i>
              Messages overview
            </Link>
          </div>
        </div>
        <div className=" pt-2 message-detail-box">
          <div className="chat-head-main d-flex align-items-center">
            <div className="chat-head-photo d-flex align-items-center me-3">
              <figure className="avatar me-2">
                <a href="#">
                  <img
                    src={
                      currentReceiver?.image
                        ? baseURL + currentReceiver?.image
                        : "/frontend/images/default.jpg"
                    }
                    alt="preview"
                  />
                </a>
              </figure>
              <h4>{currentReceiver?.username ?? ""}</h4>
            </div>
            {!currentReceiver?.Subscribe_user ? (
              <div className="chat-head-info d-flex align-items-center ms-auto">
                <Subscribe
                  price={currentReceiver?.price}
                  creatorId={currentReceiver?.id}
                ></Subscribe>
              </div>
            ) : (
              <div className="chat-head-info d-flex align-items-center ms-auto">
                <a href="#" className="btn btn-sm-boarder btn-subscribed">
                  Subscribed
                </a>
              </div>
            )}
          </div>
          <div className="pt-2 px-0">
            <div className="">
              <div className="msg-chat" style={{ height: "100%" }}>
                <div className="chat-main-in">
                  <Loader visible={loader} />
                  <Chat
                    chat={chat}
                    loader={loader}
                    user={user}
                    imageFile={imageFile}
                    videoFile={videoFile}
                    setImageFile={setImageFile}
                    setVideoFile={setVideoFile}
                    deleteChatHandler={deleteChatHandler}
                    editChatHandler={editChatHandler}
                    setEditing={setEditing}
                    setImgProgress={setImgProgress}
                    imgProgress={imgProgress}
                    setVidProgress={setVidProgress}
                    vidProgress={vidProgress}
                  />
                  <div className="token-send ms-auto text-end my-2">
                    <a href="#" className="d-inline-flex align-items-center">
                      <span>Send tip</span>
                      <figure className="ms-2">
                        <img src="/frontend/images/logo-ic.png" />
                      </figure>
                    </a>
                  </div>
                  <div className="send-chat p-2">
                    <div className="send-comment d-flex">
                      <div className="avatar me-3">
                        <figure className="avatar">
                          <img
                            src={
                              user.image
                                ? `${baseURL}${user.image}`
                                : "/frontend/images/default.jpg"
                            }
                            alt="preview"
                          />
                        </figure>
                      </div>
                      <div className="form-group w-100">
                        <input
                          type="text"
                          name="currentMessage"
                          id="currentMessage"
                          value={currentMessage}
                          onChange={(event) =>
                            setCurrentMessage(event.target.value)
                          }
                          className="form-control form-control-in"
                          placeholder="Write your message"
                          onKeyDown={(e) =>
                            e.key.toLowerCase() === "enter" && sendMessage(e)
                          }
                        />
                        <div className="image-video-chat">
                          <button>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleImage(e)}
                            />
                            <i class="ri-image-line"></i>
                          </button>
                          <button>
                            <input
                              type="file"
                              accept="video/*"
                              onChange={(e) => handleVideo(e)}
                            />
                            <i class="ri-vidicon-line"></i>
                          </button>
                        </div>
                        <button type="button" onClick={(e) => sendMessage(e)}>
                          <i className="ri-send-plane-2-line"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
