import React, { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import Suggestions from "../suggestions/suggestions";
import $ from "jquery";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../../services/services";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { toast } from "react-toastify";
import ImageDialog from "../profile/ImageDialog";
import DetailGallery from "../imageGallary/detail-gallery";
import { useSelector } from "react-redux";
import Subscribe from "../basic/subscribe";
import Menu from "../menu/menu";

export default function ProfileDetails() {
  const currentUser = useSelector((state) => state.auth);
  const navigate = useNavigate();
  console.log("currentUser", currentUser);
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState({ type: "", content: "" });

  const [user, setUser] = useState({});
  const [mediaData, setMediaData] = useState([]);

  const userId = currentUser?.user?.id;

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    get(`${ApiURL.getUser}/${userId}`).then((response) => {
      if (response.status) {
        setUser(response.user);
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imageDialogHandler = (post) => {
    navigate("/post-details/" + post?.id);

    // if (post.privacy == "public") {
    //     setOpen(true)
    //     setMediaData(post.media)
    // } else if (userId == currentUser.user.id) {
    //     setOpen(true)
    //     setMediaData(post.media)
    // } else if (user.Subscribe_user) {
    //     setOpen(true)
    //     setMediaData(post.media)
    // }
  };

  const subscribeHandle = (creatorId) => {
    let obj = {
      user_id: currentUser?.user?.id,
      creatorId: user?.id,
    };
    post(ApiURL.insertSubscribe, obj).then((resp) => {
      getUserData();
      toast.success(resp.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    });
  };

  return (
    <div className="container">
      <Navbar></Navbar>
      <div className="row pt-1">
        <div className="col-sm-8 d-none d-sm-block">
          <h4 className="ms-3">My Posts</h4>
        </div>
        <div className="col-sm-4 d-none d-sm-block">
          <div className="right-head ms-auto d-flex align-items-center justify-content-sm-end">
            <div className="swip-btn me-3">
              <figure>
                <i className="ri-exchange-line"></i>
              </figure>
              <h4>Swap</h4>
            </div>
            <div className="available-tokens dropdown">
              <Menu getMenuData={""}></Menu>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-2 pt-sm-5">
        <div className="col-md-8 left-section">
          <div className="row">
            <div className="col-12">
              <div className="user-profile-details card mb-3">
                <div className="card-inner">
                  <section id="gallery" className="mt-3">
                    <div id="image-gallery">
                      <div className="row">
                        <>
                          {user?.posts?.length === 0 && (
                            <>No post created yet</>
                          )}
                        </>
                        <>
                          {user?.posts?.length > 0 &&
                            user.posts.map((post, index) => (
                              <div
                                className="col-lg-4 col-md-6 col-sm-6 col-xs-12 image"
                                key={index}
                              >
                                <div
                                  className={
                                    post.privacy === "public"
                                      ? "img-wrapper mt-4"
                                      : userId == currentUser.user.id ||
                                        user.Subscribe_user
                                      ? "img-wrapper mt-4"
                                      : "img-wrapper img-wrapper-second mt-4"
                                  }
                                  onClick={() => imageDialogHandler(post)}
                                >
                                  {post.image &&
                                    JSON.parse(post.image).length > 0 && (
                                      <img
                                        alt="..."
                                        src={JSON.parse(post.image)[0]}
                                      />
                                    )}
                                  {post.video && (
                                    <video
                                      style={{ objectFit: "cover !important" }}
                                    >
                                      <source src={post.video} />
                                    </video>
                                  )}
                                  <div className="img-overlay">
                                    <i class="ri-lock-2-line"></i>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      </div>
                    </div>
                    <div id="overlay" style={{ display: "none" }}>
                      <div id="prevButton">
                        <i className="fa fa-chevron-left"></i>
                      </div>
                      <img src="/frontend/images/09.jpg" />
                      <div id="nextButton">
                        <i className="fa fa-chevron-right"></i>
                      </div>
                      <div id="exitButton">
                        <i className="fa fa-times"></i>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suggestions className="rightBox"></Suggestions>
        <DetailGallery
          open={open}
          setOpen={setOpen}
          data={mediaData}
        ></DetailGallery>
      </div>
    </div>
  );
}
