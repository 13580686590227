import React from "react";

const SearchResult = ({ creatorList, searchByUserHandler, focusedIndex }) => {
  return (
    <ul className="search-list">
      {creatorList.map((creator, index) => (
        <li
          style={{
            padding: "10px",
            cursor: "pointer",
            backgroundColor: focusedIndex === index ? "#f51281" : null,
          }}
          onClick={() => searchByUserHandler(creator)}
          key={index}
        >
          {creator.username}
        </li>
      ))}
    </ul>
  );
};

export default SearchResult;
