import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL } from "../../services/apiConstants";
import { patch } from "../../services/services";
import { NewPasswordScheema } from "../../utils/validation";

export default function NewPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      let res;
      if (token) {
        res = await patch(`${ApiURL.resetPassword}/${token}`, {
          password: values.password,
          confirmPassword: values.confirmPassword,
        });
      }
      if (res.status) {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        navigate("../login", { replace: true });
        setLoading(false);
      } else {
        toast.error(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        setLoading(false);
      }
    } catch (error) { }
  };

  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      validationSchema={NewPasswordScheema}
      onSubmit={(values, { setSubmitting }) => {
        handleFormSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            class="jumbotron align-items-sm-center"
            style={{ display: "flex", minHeight: "100%", minHeight: "100vh" }}
          >
            <div className="container">
              <div className="row pt-5 pt-sm-0">
                <div className="col-12 col-sm-8">
                  <div className="mx-auto">
                    <div className="signup-logo text-center">
                      <Link to={"/"}>
                        <img src="frontend/images/logo.svg" alt="" />
                      </Link>
                      <h4>New Password your account</h4>
                    </div>
                    <div className="signup-form-in">
                      <div className="form-group mb-3">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          id="password"
                          onChange={handleChange}
                          style={
                            touched.password && errors.password
                              ? { border: "1px red solid", color: "white" }
                              : { color: "white" }
                          }
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        {touched.password && errors.password && (
                          <p
                            style={{ color: "red", margin: "5px 0" }}
                            id="standard-weight-helper-text-password-login"
                          >
                            {errors.password}
                          </p>
                        )}
                        <div
                          className="text text-danger"
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm password"
                          id="confirmPassword"
                          onChange={handleChange}
                          style={
                            touched.confirmPassword && errors.confirmPassword
                              ? { border: "1px red solid", color: "white" }
                              : { color: "white" }
                          }
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                        />
                        {touched.confirmPassword && errors.confirmPassword && (
                          <p
                            style={{ color: "red", margin: "5px 0" }}
                            id="standard-weight-helper-text-confirmPassword-login"
                          >
                            {errors.confirmPassword}
                          </p>
                        )}
                        <div
                          className="text text-danger"
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                        </div>
                      </div>
                      {loading ? (
                        <button
                          type="submit"
                          disabled={loading}
                          class="btn btn-p w-100 w-sm-50"
                        >
                          <i className="fa fa-spinner fa-spin"></i>
                        </button>
                      ) : (
                        <button type="submit" class="btn btn-p w-100 w-sm-50">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 d-none d-sm-block signup-img-box">
                  <figure className="d-flex">
                    <img src="/frontend/images/img2.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
