import { EthProvider } from "./contexts/EthContext";
import Intro from "./components/Intro/";
import Setup from "./components/Setup";
import Demo from "./components/Demo";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Sign from "./components/commn/sign";
import Login from "./components/commn/login";
import Dashboard from "./components/frontend/dashboard/dashboard";
import Notification from "./components/frontend/notification/notification";
import Message from "./components/frontend/message/message";
import MessageDetails from "./components/frontend/message/messageDetails";
import Profile from "./components/frontend/profile/profile";
import Earning from "./components/frontend/earning/earning";
import MyPosts from "./components/frontend/myPost/myposts";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgetPassword from "./components/commn/forgetPassword";
import OTPPassword from "./components/commn/newPasswordOtp";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./app/store";
import profile from "./components/frontend/profile/profile";
import CreatePost from "./components/frontend/create-post/create-post";
import PostDetails from "./components/frontend/dashboard/postDetails";
import Explore from "./components/frontend/explore/explore";
import ProfileDetails from "./components/frontend/profile/profile-details";
import NewPassword from "./components/commn/newPassword";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import ChangePasswordAccount from "./components/frontend/setting/changePassword";
import CommonRoutes from "./routes/CommonRoutes";
import NewPasswordOtp from "./components/commn/newPasswordOtp";
import { useEffect, useState } from "react";
import { setSocket } from "./app/slices/socket";
import io from "socket.io-client";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import ManageSubscription from "./components/frontend/manage-subscription/manage-subscription";
import OurFans from "./components/frontend/manage-subscription/ourFans";
import configuration from "./contracts/UserContract.json";
import configuration1 from "./contracts/SimpleToken.json";
import {
  setAccount,
  setContract,
  setMetaMask,
} from "./app/slices/contractSlice";

import { setLogout } from "./app/slices/auth";
import { post } from "./services/services";
import { ApiURL } from "./services/apiConstants";
const CONTRACT_ADDRESS = configuration.networks["5777"].address;
const CONTRACT_ADDRESS1 = configuration1.networks["5777"].address;
const CONTRACT_ABI = configuration.abi;
const CONTRACT_ABI1 = configuration1.abi;
function App() {
  const provideUrl = "http://127.0.0.1:7545";
  function getLibrary(provider) {
    return new Web3(provider);
  }
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let isLoggedin = token ? true : false;

  useEffect(() => {
    // Notification()
    if (!isLoggedin) {
      dispatch(setLogout());
    }
  }, [isLoggedin, dispatch]);

  useEffect(() => {
    dispatch(setSocket());
  }, [dispatch]);
  useEffect(() => {
    account();
  }, []);

  const account = async () => {
    if (window.ethereum) {
      const web3 = new Web3(Web3.givenProvider || provideUrl);
      const result = await web3.eth.getAccounts();
      const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
      const contract1 = new web3.eth.Contract(CONTRACT_ABI1, CONTRACT_ADDRESS1);
      let obj = {
        address: result[0],
      };
      const accountStore = await post(ApiURL.accountStore, obj);
      dispatch(setContract({ contract, contract1 }));
      dispatch(setAccount({ account: result[0] }));
      dispatch(setMetaMask({ metamask: false }));
    } else {
      dispatch(setMetaMask({ metamask: true }));
      toast.error(
        <>
          <p>Please install meta mask</p>
          <a href="https://metamask.io/" target="_blank" rel="noreferrer">
            click here
          </a>
        </>
      );
    }
  };

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Provider store={store}>
          <div id="App">
            <div className="container-fluid">
              <Routes>
                {/* This routing for Home page */}
                <Route
                  path="/"
                  element={
                    <CommonRoutes isLoggedIn={isLoggedin}>
                      <Home />
                    </CommonRoutes>
                  }
                ></Route>
                {/* This routing for sign up page */}
                <Route
                  path="sign/:userType"
                  element={
                    <CommonRoutes isLoggedIn={isLoggedin}>
                      <Sign />
                    </CommonRoutes>
                  }
                ></Route>
                {/* This routing for Login page */}
                <Route
                  path="login"
                  element={
                    <CommonRoutes isLoggedIn={isLoggedin}>
                      <Login />
                    </CommonRoutes>
                  }
                ></Route>
                {/* This routing for Forget password page */}
                <Route
                  path="forget"
                  element={
                    <CommonRoutes isLoggedIn={isLoggedin}>
                      <ForgetPassword />
                    </CommonRoutes>
                  }
                ></Route>
                {/* This routing for Forget password otp page */}
                <Route
                  path="otp"
                  element={
                    <CommonRoutes isLoggedIn={isLoggedin}>
                      <NewPasswordOtp />
                    </CommonRoutes>
                  }
                ></Route>
                {/* This routing for reset password  page */}
                <Route
                  path="reset-password/:token"
                  element={
                    <CommonRoutes isLoggedIn={isLoggedin}>
                      <NewPassword />
                    </CommonRoutes>
                  }
                ></Route>
                {/* This routing for dashboard page */}
                <Route
                  path="dashboard"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <Dashboard />
                    </ProtectedRoutes>
                  }
                />
                {/* This routing for notification page */}
                <Route
                  path="notification"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <Notification />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for my post page */}
                <Route
                  path="my-posts"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <MyPosts />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for message page */}
                <Route
                  path="message"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <Message />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for change password page */}
                <Route
                  path="change-password-account"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <ChangePasswordAccount />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for chat detail page */}
                <Route
                  path="message-details/:receiverId"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <MessageDetails />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for user profile page */}
                <Route
                  path="profile"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <Profile />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for user Profile details page */}
                <Route
                  path="profile-details/:userId"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <ProfileDetails />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for earning page */}
                <Route
                  path="earning"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <Earning />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for create post page */}
                <Route
                  path="create-post"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <CreatePost />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for create post detail page */}
                <Route
                  path="post-details/:id"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <PostDetails />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for explore page */}
                <Route
                  path="explore"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <Explore />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for mange subscription page */}
                <Route
                  path="manage-subscription"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <ManageSubscription />
                    </ProtectedRoutes>
                  }
                ></Route>
                {/* This routing for our fans page */}
                <Route
                  path="our-fans"
                  element={
                    <ProtectedRoutes isLoggedIn={isLoggedin}>
                      <OurFans />
                    </ProtectedRoutes>
                  }
                ></Route>
              </Routes>
            </div>
          </div>
          <ToastContainer limit={1} autoClose={8000} />
        </Provider>
      </BrowserRouter>
    </Web3ReactProvider>
  );
}

export default App;
