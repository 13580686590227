// live
// export const baseURL = 'https://tedallal.devtechnosys.tech/api/';

// stateging
export const baseURL = "https://cuminu.devtechnosys.tech:17372/";

// local
// export const baseURL = "http://localhost:5001/";

export const ApiURL = {
  register: "register",
  login: "login",
  forget: "forget-password",
  resetPassword: "reset-password",
  verifyOTP: "verify-otp",
  createPost: "api/v2/post",
  getPosts: "api/v2/post",
  findMe: "/api/v2/user",
  updateMe: "/api/v2/user",
  getUser: "/api/v2/user",
  getUserImage: "/api/v2/user/get-user-image",
  verifyAccount: "verify-account",
  changePassword: "change-password",
  imageUpload: "api/v2/post/image-upload",
  getChat: "/api/v2/chat",
  getChatActiveUser: "/api/v2/chat/active-user",
  addChat: "/api/v2/chat",
  getUserList: "/api/v2/user/list",
  getCreatorList: "/api/v2/user/creator",
  getSuggestCreator: "/api/v2/user/suggest-creator",
  getAttributes: "/api/v2/user/attributes",
  getNotificationList: "/api/v2/notification",
  likeComment: "/api/v2/comment-like",
  deletePost: "api/v2/post/delete-post",
  postTips: "api/v2/post/post-tips",
  updatePost: "api/v2/post/update-post",
  readNotification: "/api/v2/notification/read",
  commentReply: "api/v2/comment-reply",
  chatImage: "api/v2/chat/chat-image",
  chatVideo: "api/v2/chat/chat-video",
  getCategory: "/api/v2/post/category",
  getPostDetails: "/api/v2/post/get-post-details",
  postImage: "api/v2/post/post-image",
  verifyAccountLink: "verify-link-send",
  getPostComments: "api/v2/post/get-comment",
  deleteUserChat: "api/v2/chat/delete-user-chat/",
  insertSubscribe: "api/v2/subscribe/insert",
  subscribeUser: "api/v2/subscribe/subscribe-user",
  ourFans: "api/v2/subscribe/our-fans",
  accountStore: "api/v2/account/store",
  likeUser: "api/v2/like-user-list",
};
