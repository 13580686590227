import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../../services/apiConstants";
import ChatImagePreview from "./chatImagePreview";
import ChatVideoPreview from "./videoImagePreview";
const Chat = ({
  chat,
  loader,
  user,
  editChatHandler,
  deleteChatHandler,
  imageFile,
  videoFile,
  setImageFile,
  setVideoFile,
  setImgProgress,
  imgProgress,
  vidProgress,
  setVidProgress,
}) => {
  const chatRef = useRef();
  const formatTime = (time) => {
    return `${new Date(time).getDate()}/${
      new Date(time).getMonth() + 1
    }/${new Date(time).getFullYear()}
            - ${new Date(time).getHours()}:${new Date(time).getMinutes()}
            `;
  };
  useEffect(() => {
    chatRef.current.scrollIntoView();
  }, [chat]);

  return (
    <>
      <div className="chat-main">
        {(imageFile || videoFile) && (
          <div className="image-preview-box">
            <div className="image-added-list">
              <ul>
                {imageFile && (
                  <li>
                    <ChatImagePreview
                      file={imageFile}
                      imgProgress={imgProgress}
                    />
                    <button
                      type="button"
                      onClick={() => setImageFile(null)}
                      class="iocn-btn ms-2"
                    >
                      <i class="ri-close-fill"></i>
                    </button>
                  </li>
                )}
                {videoFile && (
                  <li>
                    <ChatVideoPreview
                      file={videoFile}
                      vidProgress={vidProgress}
                    />
                    <button
                      type="button"
                      onClick={() => setVideoFile(null)}
                      class="iocn-btn ms-2"
                    >
                      <i class="ri-close-fill"></i>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
        <div className="chating-wrap">
          <ul className="chat-details">
            {!loader &&
              chat.map((message, i) => (
                <div key={i}>
                  {message.sender_id.toString() !== user.id.toString() ? (
                    <li
                      className="my-chat"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="chat-user-in">
                        <div className="chat-details-in">
                          <div className="msg-action">
                            <div class="dropdown">
                              <button
                                class="iocn-btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="ri-more-2-fill"></i>
                              </button>
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                {!message?.attechment && !message?.video && (
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => editChatHandler(message)}
                                    >
                                      {/* <img src="/frontend/images/edit-03.svg" /> */}
                                      Edit Message
                                    </button>
                                  </li>
                                )}
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => deleteChatHandler(message)}
                                    type="button"
                                  >
                                    Delete Message
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {message.body}
                          {message?.attechment != null ? (
                            <a
                              href={message?.attechment}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={message?.attechment}
                                width="250"
                                height={"150"}
                              ></img>
                            </a>
                          ) : null}
                          {message?.video != null ? (
                            <video
                              width="250"
                              height="150"
                              controls
                              src={message.video}
                              autoPlay
                            >
                              <source
                                src={message.video}
                                type="video/mp4"
                              ></source>
                              <source
                                src={message.video}
                                type="video/ogg"
                              ></source>
                            </video>
                          ) : null}
                        </div>
                        <figure className="avatar ms-3">
                          <img
                            alt=""
                            src={
                              message?.senderMessage?.image != null
                                ? baseURL + message?.senderMessage?.image
                                : "/frontend/images/default.jpg"
                            }
                          />
                        </figure>
                      </div>
                      <p className="chat-time">
                        <span class="last-seen me-2 active">
                          <i class="ri-check-double-line"></i>
                        </span>
                        {formatTime(message.createdAt)}
                      </p>
                    </li>
                  ) : (
                    <li
                      className="user-chat"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="chat-user-in">
                        <figure className="avatar me-3">
                          <img
                            alt=""
                            src={
                              user?.image != null
                                ? baseURL + user?.image
                                : "/frontend/images/default.jpg"
                            }
                          />
                        </figure>
                        <div className="chat-details-in">
                          <div className="msg-action"></div>
                          {message.body}
                          {message?.attechment != null ? (
                            <a
                              href={message?.attechment}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={message?.attechment}
                                width="250"
                                height={"150"}
                              ></img>
                            </a>
                          ) : null}
                          {message?.video != null ? (
                            <video
                              width="250"
                              height="150"
                              controls
                              src={message.video}
                              autoPlay
                            >
                              <source
                                src={message.video}
                                type="video/mp4"
                              ></source>
                              <source
                                src={message.video}
                                type="video/ogg"
                              ></source>
                            </video>
                          ) : null}
                        </div>
                      </div>
                      <p className="chat-time text-start">
                        {" "}
                        {formatTime(message.createdAt)}
                        <span class="last-seen ms-2">
                          <i class="ri-check-double-line"></i>
                        </span>
                      </p>
                    </li>
                  )}
                </div>
              ))}
          </ul>
          <div ref={chatRef}></div>
        </div>
      </div>
    </>
  );
};

export default Chat;
