import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiURL } from "../../services/apiConstants";
import { post } from "../../services/services";
import { ForgetPasswordSchema } from "../../utils/validation";
export default function ForgetPassword() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const forgetPassword = (values) => {
    setLoading(true);
    post(ApiURL.forget, values).then((res) => {
      if (res.status) {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate(`../otp?user=${values.email}`, { replace: true });
        setLoading(false);
      } else {
        toast.error(res?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={ForgetPasswordSchema}
      onSubmit={(values, { setSubmitting }) => {
        forgetPassword(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form>
          <div
            className="jumbotron align-items-sm-center"
            style={{ display: "flex", minHeight: "100%", minHeight: "100vh" }}
          >
            <div className="container">
              <div className="row pt-5 pt-sm-0">
                <div className="col-12 col-sm-8">
                  <div className="mx-auto">
                    <div className="signup-logo text-center">
                      <Link to={"/"}>
                        <img src="/frontend/images/logo.svg" alt="" />
                      </Link>
                      <h4>Forget Password in to your account</h4>
                    </div>
                    <div className="signup-form-in">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Email address"
                          id="email"
                          onChange={handleChange}
                          style={
                            touched.email && errors.email
                              ? { border: "1px red solid", color: "white" }
                              : { color: "white" }
                          }
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {touched.email && errors.email && (
                          <p
                            style={{ color: "red", margin: "5px 0" }}
                            id="standard-weight-helper-text-email-login"
                          >
                            {errors.email}
                          </p>
                        )}
                        <div
                          className="text text-danger"
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="forgot-pass mb-3">
                        <Link to={"/login"}>Login?</Link>
                      </div>
                      {loading ? (
                        <button
                          type="submit"
                          disabled={loading}
                          class="btn btn-p w-100 w-sm-50"
                        >
                          <i className="fa fa-spinner fa-spin"></i>
                        </button>
                      ) : (
                        <button type="submit" class="btn btn-p w-100 w-sm-50">
                          Submit
                        </button>
                      )}
                      {/* <div className="login-already mt-2">
                        <p>Are you a new user? <Link to={"/sign"}>Signup</Link></p>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 d-none d-sm-block signup-img-box">
                  <figure className="d-flex">
                    <img src="/frontend/images/img2.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
