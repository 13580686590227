import ReactDOM from "react-dom";
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

class ImageCrop extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: this.props.file || null,
      crop: {
        unit: "%",
        x: 100,
        y: 100,
        width: 50,
        height: 50,
      },
    };
    console.log(this.props.image);
  }
  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  saveHandler = () => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    // this.setState({ crop });
    this.props.setImageUser(this.state.croppedImageUrl);
    // console.log(this.state);
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // canvas.width = crop.width;// image.naturalWidth// crop.width;
    //canvas.height = crop.height;//image.naturalHeight //crop.height;
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    const ctx = canvas.getContext("2d");
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        console.log(blob);
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        this.props.setImageBlob(blob);
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleClose(this.props.type)}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              this.props.type.toLowerCase() === "image"
                ? this.props.handleDoneClose(croppedImageUrl, "image")
                : this.props.handleDoneClose(croppedImageUrl, "backgroundImage")
            }
            autoFocus
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default ImageCrop;
