import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setType } from "../../app/slices/tokenSlice";
import Metamask from "../frontend/metamask/metamask";

const Home = () => {
  const metaMask = useSelector((state) => state.contract);
  return (
    <>
      <div className="login-page">
        <div className="login-bg">
          <img src="/frontend/images/login-bg.jpg" alt="img" />
        </div>
        <div className="login-wrap text-center">
          <div className="login-logo">
            <Link to="/">
              <img src="/frontend/images/logo.svg" alt="img" />
            </Link>
            <p>Now with full tipping functionality</p>
          </div>
          <div className="login-btns">
            <Link to="/sign/creator" className="btn-creator">
              Creator Register
            </Link>
            <Link to="/sign/fan" className="btn-fan">
              Fan Register
            </Link>
          </div>
          <div className="login-already">
            <p>
              Already have an account? <Link to="/login">Log In</Link>
            </p>
            <Metamask visible={metaMask?.metamask}></Metamask>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
