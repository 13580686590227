import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { setCurrentUser, setLogout } from "../../../app/slices/auth";
import { ApiURL } from "../../../services/apiConstants";
import { get } from "../../../services/services";

export default function Navbar({ lists }) {
  const is_type = useSelector((state) => state?.token?.userType);
  const location = useLocation();
  const pathName = location.pathname;
  const [user, setUser] = useState({});
  const [notificationCount, setNotificationCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    get(ApiURL.findMe).then((response) => {
      if (response.status) {
        setUser(response.user);
        dispatch(setCurrentUser(response.user));
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleLogout();
      }
    });
  }, [dispatch]);

  useEffect(() => {
    getCount();
  }, [lists]);

  const getCount = () => {
    get(`${ApiURL.getNotificationList}`).then((response) => {
      if (response?.status) {
        setNotificationCount(response?.count);
      }
    });
  };
  const handleLogout = () => {
    dispatch(setLogout());
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-lg">
          <Link to={"/dashboard"} className="navbar-brand">
            <img src="/frontend/images/logo.svg" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navmenu"
          >
            <i class="ri-menu-line"></i>
          </button>
          <div className="collapse navbar-collapse" id="navmenu">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link
                  to={"/notification"}
                  className={
                    pathName == "/notification"
                      ? "nav-link nav-link-active"
                      : "nav-link"
                  }
                >
                  <span className=" align-top badge text-bg-secondary notification-cum">
                    {notificationCount}
                  </span>{" "}
                  Notifications
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/dashboard"}
                  className={
                    pathName == "/dashboard"
                      ? "nav-link nav-link-active"
                      : "nav-link"
                  }
                >
                  <i className="align-middle ri-compass-3-line"></i> Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/explore"}
                  className={
                    pathName == "/explore"
                      ? "nav-link nav-link-active"
                      : "nav-link"
                  }
                >
                  <i className="align-middle ri-compass-3-line"></i> Explore
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to={"/message"}
                  className={
                    pathName == "/message"
                      ? "nav-link nav-link-active"
                      : "nav-link"
                  }
                >
                  <i className="align-middle ri-chat-1-line"></i> Messages
                </Link>
              </li>

              {user && user.role === "creator" && (
                <li className="nav-item">
                  <Link
                    to={"/create-post"}
                    className={
                      pathName === "/create-post"
                        ? "nav-link nav-link-active"
                        : "nav-link"
                    }
                  >
                    <i className="align-middle ri-add-circle-line"></i> Create
                  </Link>
                </li>
              )}
              {user && user.role === "creator" && (
                <li className="nav-item">
                  <Link
                    to={"/my-posts"}
                    className={
                      pathName === "/my-posts"
                        ? "nav-link nav-link-active"
                        : "nav-link"
                    }
                  >
                    <i className="align-middle ri-add-circle-line"></i> My Posts
                  </Link>
                </li>
              )}
              {user && user.role === "fan" && (
                <li className="nav-item">
                  <Link
                    to={"/manage-subscription"}
                    className={
                      pathName == "/manage-subscription"
                        ? "nav-link nav-link-active"
                        : "nav-link"
                    }
                  >
                    <i class="align-middle ri-file-list-line"></i> Manage
                    subscription
                  </Link>
                </li>
              )}
              {user && user.role === "creator" && (
                <li className="nav-item">
                  <Link
                    to={"/our-fans"}
                    className={
                      pathName == "/our-fans"
                        ? "nav-link nav-link-active"
                        : "nav-link"
                    }
                  >
                    <i class="align-middle ri-group-line"></i> Our Fans
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link
                  to={"/profile"}
                  className={
                    pathName == "/profile"
                      ? "nav-link nav-link-active"
                      : "nav-link"
                  }
                >
                  <i className="align-middle ri-user-line"></i> Profile
                </Link>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={handleLogout}
                className="nav-item"
              >
                <span className="nav-link">
                  <i className="align-middle ri-logout-circle-line"></i> Logout
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
