import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export default function DeleteChatDialog({
  open,
  deleteUserChat,
  handleClose,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ background: "#1e1e1e" }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#fff" }}
          >
            Are you sure you want to delete this chat
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ background: "#1e1e1e" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteUserChat} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
