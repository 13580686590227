import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ApiURL, baseURL } from "../../../services/apiConstants";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../wallet/connector";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  post as postApi,
  deleteApi,
  patch,
  get,
} from "../../../services/services";
import InfiniteScroll from "react-infinite-scroll-component";
import Tiny from "../tinyEditor/tiny";
import $ from "jquery";
import Multiselect from "multiselect-react-dropdown";
import DeletePostDialog from "./DeletePostDialog";
import Carousel from "react-material-ui-carousel";
import EmojiPicker from "emoji-picker-react";
import ImagePreview from "../../commn/ImagePreview";
import VideoPreview from "../../commn/VideoPreview";
import ImageCrop from "../ImageCrop/ImageCrop";
import Tooltip from "@mui/material/Tooltip";
import ImageGallary from "../imageGallary";
import CircularStatic from "../progress/progressBar";
import Web3 from "web3";
import Subscribe from "../basic/subscribe";
import { MentionsInput, Mention } from "react-mentions";
import TipModal from "./tipModal";
// import { Mention, MentionsInput } from "react-mentions";

const PostList = ({
  posts,
  setPosts,
  setCurrentPage,
  currentPage,
  getPostData,
  setPostPage,
  postCount,
  setPostCount,
  postPage,
  setPostLimit,
  postLimit,
  currentCommentPage,
  commentPaginationHandler,
  myData,
  setMyData,
  hasData,
  setSearchCategory,
  setListClicked,
}) => {
  const { socket } = useSelector((state) => state.socket);
  const { user } = useSelector((state) => state.auth);
  const commentRef = useRef();
  const txtRef = useRef();
  const formRef = useRef();
  const [editor, setEditor] = useState("");
  const [postId, setPostId] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [currentDeletePost, setCurrentDeletePost] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [reply, setReply] = React.useState("");
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const [isShow, setIsShow] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [privacy, setPrivacy] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [openPicker, setOpenPicker] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [imageBlob, setImageBlob] = useState(false);
  const [croppedImage, setCroppedImage] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const videoInputRef = useRef();
  const imageInputRef = useRef();
  const categoryRef = useRef();
  const picker = useRef();
  const [creators, setCreators] = useState([]);
  const [vidProgress, setVidProgress] = useState(0);
  const [imgProgress, setImgProgress] = useState(0);
  const [openTipModal, setOpenTipModal] = useState(false);
  const [storeOriginalImage, setStoreOriginalImage] = useState([]);
  const [originalImage, setOriginalImage] = useState(null);
  const { contract, account } = useSelector((state) => state.contract);
  const { userData, setUserData } = useState([]);
  const [myPostData, setMyPostData] = useState({});

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (picker.current && !picker.current.contains(event.target)) {
      setOpenEmoji(false);
    }
  };

  const searchCat = (e) => {
    setSearchCategory((prevState) => {
      // for()
      return [...prevState, e.name];
    });
    setListClicked((prevState) => [...prevState, { name: e.name, id: e.id }]);
    setOpen(false);
  };
  const handleChangeData = (e) => {
    setDescription(e.target.value);
  };

  const getCreator = async () => {
    get(ApiURL.getCreatorList + "?search=&attribute=All&page=1&limit=15").then(
      (res) => {
        let arr = [];
        console.log("aaaayyyaa", res.creators);
        for (let data of res.creators) {
          console.log("data", data);
          let obj = {
            id: data?.username,
            display: data?.username,
          };
          arr.push(obj);
        }
        setCreators(arr);
      }
    );
  };

  const imageCloseHandler = (index) => {
    setCurrentImage("");
    setImageUrl(null);
    setImageBlob("");
    const _images = [];
    const _blob = [];
    const _originalImg = [];
    for (const _index in image) {
      if (Number(_index) === Number(index)) {
        continue;
      } else {
        _images.push(image[_index]);
        _originalImg.push(storeOriginalImage[_index]);
        _blob.push(croppedImage[_index]);
      }
    }
    setImage([..._images]);
    setStoreOriginalImage([..._originalImg]);
    setCroppedImage([..._blob]);
  };

  const videoCloseHandler = (index) => {
    const formattedVids = [];
    for (const vidIndex in video) {
      if (Number(vidIndex) === Number(index)) {
        continue;
      } else {
        formattedVids.push(video[vidIndex]);
      }
    }
    setVideo([...formattedVids]);
  };

  const showComment = (id) => {
    setPosts((prevState) => {
      const posts = [];
      for (const post of prevState) {
        if (post.id.toString() === id.toString()) {
          if (post.show) {
            post.show = false;
          } else {
            post.show = true;
            post.showLikes = false;
          }
          posts.push(post);
        } else {
          posts.push(post);
        }
      }
      return posts;
    });
  };

  const showLike = async (id) => {
    setPosts((prevState) => {
      const posts = [];
      for (const post of prevState) {
        if (post.id.toString() === id.toString()) {
          if (post.showLikes) {
            post.showLikes = false;
          } else {
            post.show = false;
            post.showLikes = true;
          }
          posts.push(post);
        } else {
          posts.push(post);
        }
      }
      return posts;
    });
  };

  // console.log(posts)

  const handleSelect = (e) => {
    setSelectCategory(e);
    setCategoryData(e);
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  const likeHandle = (postId) => {
    socket.emit("likePost", { userId: user.id, postId: postId });
  };

  const getCategory = () => {
    get(ApiURL.getCategory).then((res) => {
      if (res.status) {
        let data = [];
        res?.posts?.map((value) => {
          data.push({ name: value?.name, id: value?.id });
        });
        setCategory(data);
      }
    });
  };
  useEffect(() => {
    getCategory();
    getCreator();
  }, []);

  const handleClickOpen = (post) => {
    setCurrentDeletePost(post);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentDeletePost(null);
    setOpen(false);
  };

  const videoUpload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    postApi(ApiURL.chatVideo, formData, 1, {
      onUploadProgress: (progressEvent) => {
        setVidProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    }).then((res) => {
      setVideo((prevState) => [...prevState, res.link]);
    });
  };
  const imageUpload = (e) => {
    const formData = new FormData();
    // formData.append('file', imageBlob);
    // formData.append('originalImage', originalImage)
    formData.append("file", e.target.files[0]);
    formData.append("originalImage", e.target.files[0]);
    postApi(ApiURL.postImage, formData, 1, {
      onUploadProgress: (progressEvent) => {
        setImgProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    }).then((res) => {
      setImage((prevState) => [...prevState, res.thumb]);
      setStoreOriginalImage((prevState) => [...prevState, res.original]);
      setLoading(false);
    });
  };

  const emojiHandler = (e) => {
    setDescription((prevState) => (prevState += e.emoji));
  };
  /**
   * Testing porpuse
   */
  const { active, library, connector, activate, deactivate } = useWeb3React();
  console.log("account", account);
  async function connect() {
    try {
      await activate(injected);
    } catch (ex) {}
  }

  const findPostLike = (post) => {
    if (post && post.postLikes.length) {
      for (const like of post.postLikes) {
        if (like.id === user.id) {
          return true;
        }
      }
      return false;
    }
    return false;
  };
  useEffect(() => {
    socket.on("newLiked", ({ postLiked }) => {
      if (postLiked && posts.length) {
        setPosts((prevState) => {
          const tempState = [...prevState];
          const updatedPosts = tempState.map((pState) => {
            if (pState.id === postLiked.id) {
              pState.postLikes = postLiked.postLikes;
            }
            return pState;
          });
          return updatedPosts;
        });
      }
    });
  }, [socket, setPosts, posts.length]);

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  function timeSince(date) {
    const seconds =
      Math.floor((Date.now() - new Date(date).getTime()) / 1000) || 0;
    const interval = intervals.find((i) => i.seconds < seconds);
    const count = Math.floor(seconds / interval?.seconds) || 0;
    if (count === 0) {
      return "0 second ago";
    }
    return `${count} ${interval?.label}${count !== 1 ? "s" : ""} ago`;
  }

  const findLikes = (post) => {
    if (post.postLikes.length > 1) {
      return `${post.postLikes.length} likes`;
    } else if (post.postLikes.length === 1) {
      return `${post.postLikes.length} like`;
    } else {
      return;
    }
  };

  const deletePost = () => {
    deleteApi(`${ApiURL.deletePost}/${currentDeletePost.id}`).then(
      (response) => {
        if (response.status) {
          getPostData();
          toast.success(response?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        handleClose();
      }
    );
  };

  const handleRemove = (e) => {
    setCategoryData(e);
  };

  const editPost = (data) => {
    let cat = data?.category?.map((v) => {
      return { id: v.id, name: v.name };
    });
    setDescription(data?.description);
    setImage(JSON.parse(data?.image) || []);
    setVideo(JSON.parse(data?.video) || []);
    setStoreOriginalImage(JSON.parse(data?.originalImage) || []);
    setCategoryData(cat);
    setIsShow(true);
    // setEditor(data?.description);
    setPostId(data?.id);
    setPrivacy(data?.privacy);
  };

  const handleUpdate = () => {
    if (description?.length <= 0 || description?.trim()?.length <= 0) {
      toast.error("Please enter valid description", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return;
    }
    let arrFinal = categoryData.map((val) => val.id);
    let temp = JSON.stringify(arrFinal);
    if (arrFinal.length == 0) {
      toast.error("Please select category", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return;
    }
    let obj = {
      id: postId,
      description: description,
      image: JSON.stringify(image),
      video: JSON.stringify(video),
      originalImage: JSON.stringify(storeOriginalImage),
      privacy: privacy,
      category: temp,
    };
    patch(ApiURL.updatePost, obj).then((response) => {
      if (response.status) {
        document.getElementById("closeModal").click();
        toast.success(response?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        getPostData();
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setOriginalImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageUrl(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    setLoading(true);
    // setOpenPicker(true);

    imageUpload(e);
  };

  const handleDoneClose = (croppedImageUrl) => {
    // setCroppedImage(croppedImageUrl);
    // imageUpload()
    // setCurrentImage(null);
    // setOpenPicker(false);
  };

  const handleImageClickOpen = () => {
    setOpenPicker(true);
  };

  const handleImageClose = () => {
    setLoading(false);
    setOpenPicker(false);
    setCurrentImage(null);
    setImageUrl(null);
  };

  useEffect(() => {
    socket.on("newComment", ({ postComment }) => {
      if (
        postComment &&
        postComment.commentsPosts &&
        postComment.commentsPosts.length > 0 &&
        postComment.commentsPosts[0].parentId
      ) {
        setPosts((prevState) => {
          const tempState = [...prevState];
          const updatedPosts = tempState.map((pState) => {
            if (pState.id === postComment.id) {
              pState.commentsPosts.map((comment) => {
                if (!comment.commentReply) {
                  comment.commentReply = [];
                }
                if (comment.id === postComment.commentsPosts[0].parentId) {
                  comment?.commentReply?.unshift(...postComment.commentsPosts);
                }
                return comment;
              });
            }
            return pState;
          });
          return updatedPosts;
        });

        // const tempPosts = [...posts];

        // for (const _post of tempPosts) {
        //   if (_post.id === postComment.commentPostId){
        //     _post.commentReply
        //   }
        // }
      } else {
        if (postComment && posts.length) {
          setPosts((prevState) => {
            const tempState = [...prevState];
            const updatedPosts = tempState.map((pState) => {
              if (pState.id === postComment.id) {
                pState?.commentsPosts?.unshift(...postComment.commentsPosts);
              }
              return pState;
            });
            return updatedPosts;
          });
        }
      }
    });
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on("deletedComment", ({ deletedComment }) => {
        get(`${ApiURL.getPostDetails}/${deletedComment?.commentPostId}`).then(
          (res) => {
            if (res.status) {
              const data = [];
              setPosts((prevState) => {
                const data = [];
                for (const post of prevState) {
                  if (
                    post.id.toString() ===
                    deletedComment?.commentPostId.toString()
                  ) {
                    post.commentsPosts = res?.posts?.commentsPosts;
                    console.log("check", res?.posts?.commentsPosts);
                    data.push(post);
                  } else {
                    data.push(post);
                  }
                }
                return data;
              });
            }
          }
        );
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on("editComment", ({ editComment }) => {
        get(`${ApiURL.getPostDetails}/${editComment?.commentPostId}`).then(
          (res) => {
            if (res.status) {
              setPosts((prevState) => {
                const data = [];
                for (const post of prevState) {
                  if (
                    post.id.toString() === editComment?.commentPostId.toString()
                  ) {
                    post.commentsPosts = res?.posts?.commentsPosts;
                    post.editComment = false;
                    data.push(post);
                  } else {
                    data.push(post);
                  }
                }
                return data;
              });
            }
          }
        );
        setCurrentComment("");
      });
    }
  }, [socket]);

  const handleReply = (userId, postId, commentId) => {
    if (reply?.length <= 0 || reply?.trim()?.length <= 0) {
      return toast.error("Please enter a message", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
    socket.emit("commentPost", {
      userId: userId,
      postId: postId,
      content: reply,
      parentId: commentId,
    });
    setReply("");
    replyHandler(postId, commentId);
  };
  const commentLikeHandler = (data, postId) => {
    postApi(`${ApiURL.likeComment}`, data).then((response) => {
      if (response.status) {
        get(`${ApiURL.getPostDetails}/${postId}`).then((res) => {
          if (res.status) {
            setPosts((prevState) => {
              const data = [];
              for (const post of prevState) {
                if (post.id.toString() === postId.toString()) {
                  post.commentsPosts = res?.posts?.commentsPosts;
                  data.push(post);
                } else {
                  data.push(post);
                }
              }
              return data;
            });
          }
        });
      }
    });
  };
  const updateComment = (postId) => {
    socket.emit("editComments", {
      commentId: currentComment.id,
      content: currentComment.content,
    });
  };
  const editHandler = (content) => {
    let tempData = [...posts];
    tempData = tempData?.map((item) => {
      return item?.id == content?.commentPostId
        ? { ...item, editComment: true }
        : { ...item, editComment: false };
    });
    setPosts(tempData);
    setCurrentComment(content);
  };
  const replyHandler = (postId, commentId) => {
    const cPosts = [...posts];
    for (const _post of cPosts) {
      if (Number(_post.id) === Number(postId)) {
        for (const _comment of _post.commentsPosts) {
          if (_comment.id === commentId) {
            if (_comment.show === "1") {
              _comment.show = "0";
            } else {
              _comment.show = "1";
            }
          } else {
            _comment.show = "0";
          }
        }
      }
    }
    setPosts([...cPosts]);
  };
  const deleteHandler = (id, postId) => {
    socket.emit("deleteComments", { commentId: id });
  };

  const handleOpenTipModal = (data) => {
    setMyPostData(data);
    setOpenTipModal(true);
  };
  const subscribeHandle = async (post) => {
    let obj = {
      user_id: user.id,
      creatorId: post?.users?.id,
    };
    if (post?.users?.accounts.length == 0) {
      return toast.error("User account address not found.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
    const test = await contract[1].methods
      .transfer(
        post?.users?.accounts[0]?.account_address,
        Number(post?.users?.price)
      )
      .send({ from: account });
    if (test.status) {
      postApi(ApiURL.insertSubscribe, obj).then((resp) => {
        toast.success(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        getPostData();
        setMyData(myData + 1);
      });
    }
  };
  useEffect(() => {
    getPostData();
  }, [postLimit]);

  return (
    <>
      <InfiniteScroll
        dataLength={posts.length}
        next={() => getPostData(10)}
        hasMore={hasData}
      >
        {posts.length > 0 &&
          posts.map((post, index) => (
            // (post.subscribe_user || post.privacy == "public" || post.userId == user.id) &&
            <div className="post-box card" key={index}>
              <div className="card-inner">
                <div className="post-header d-flex align-items-center mb-2">
                  <div className="profile-sec d-flex align-items-center">
                    <div className="profile-img me-3">
                      <figure className="avatar">
                        <Link
                          to={
                            post?.users.id != user.id &&
                            `/profile-details/${post?.users?.id}`
                          }
                        >
                          <img
                            src={
                              post?.users?.image != null
                                ? baseURL + post?.users?.image
                                : "/frontend/images/default.jpg"
                            }
                            alt="img"
                          />
                        </Link>
                      </figure>
                    </div>
                    <div className="profile-info">
                      <h4>
                        <Link
                          to={
                            post?.users.id != user.id &&
                            `/profile-details/${post?.users?.id}`
                          }
                        >
                          {post?.users?.username}
                        </Link>
                      </h4>
                      <span>{timeSince(post?.createdAt)}</span>
                    </div>
                  </div>
                  <div className="post-action-main ms-auto">
                    {post?.userId == user?.id ? (
                      <>
                        <Tooltip title="Edit">
                          <button
                            className="iocn-btn ms-2"
                            data-toggle="modal"
                            onClick={() => editPost(post)}
                            data-target="#exampleModal"
                            title="Edit Post"
                          >
                            <i
                              className="ri-edit-fill"
                              aria-hidden="true"
                              style={{
                                color: "white",
                              }}
                            ></i>
                          </button>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <button
                            className="iocn-btn ms-2"
                            title="Delete Post"
                            onClick={() => handleClickOpen(post)}
                          >
                            <i
                              className="ri-delete-bin-fill"
                              aria-hidden="true"
                              style={{
                                color: "white",
                              }}
                            ></i>
                          </button>
                        </Tooltip>
                      </>
                    ) : null}
                  </div>
                  {user?.id != post.userId && !post.subscribe_user ? (
                    <div className="ms-auto">
                     
                      <Subscribe
                        data={post}
                        price={post?.users?.price}
                        creatorId={post?.users.id}
                      ></Subscribe>
                    </div>
                  ) : null}
                </div>
                <div className="post-info">
                  <div className="post-text">
                    <div>{post?.description}</div>
                    {post?.media.length > 0 && (
                      <ImageGallary images={post?.media} post={post} />
                    )}
                  </div>
                </div>
                <ul
                  style={{
                    margin: "10px 0",
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {post?.category.map((val) => {
                    return (
                      <li className="tags" onClick={() => searchCat(val)}>
                        #{val.name}
                      </li>
                    );
                  })}
                </ul>
                <div className="post-action">
                  <div className="d-flex">
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="icon-with-text"
                    >
                      {" "}
                      {findPostLike(post) ? (
                        <button
                          className="iocn-btn"
                          onClick={() => likeHandle(post?.id)}
                          style={{ marginRight: "10px" }}
                        >
                          <i
                            className="ri-heart-fill like-heart"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      ) : (
                        <button
                          className="iocn-btn"
                          title="Like Post"
                          onClick={() => likeHandle(post?.id)}
                          style={{ marginRight: "10px" }}
                        >
                          <i className="ri-heart-line"></i>
                        </button>
                      )}{" "}
                      {/* <p style={{ color: "#878787" }}> */}
                      <span
                        onClick={() => {
                          showLike(post.id);
                        }}
                      >
                        {findLikes(post)}
                      </span>
                      {/* </p> */}
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                        marginLeft: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="icon-with-text"
                      onClick={() => {
                        showComment(post.id);
                      }}
                    >
                      <button
                        className="iocn-btn"
                        style={{ marginRight: "10px" }}
                      >
                        <i className="ri-chat-3-line"></i>
                      </button>
                      <p style={{ color: "#878787" }}>
                        {post.commentCount > 0
                          ? post.commentCount + " comments"
                          : post.commentCount + " comment"}
                      </p>
                    </div>
                    <div className="ms-auto">
                      {post?.userId != user?.id && (
                        <div className="token-send">
                          <a
                            href="javascript:void(0)"
                            className="d-flex align-items-center"
                          >
                            <span onClick={() => handleOpenTipModal(post)}>
                              Send tip
                            </span>
                            <figure className="ms-2">
                              <img src="/frontend/images/logo-ic.png" alt="" />
                            </figure>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {post.show && (
                  <>
                    <div
                      className="send-comment d-flex"
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <div className="me-3">
                        <figure className="avatar">
                          <img
                            height="40px"
                            width="40px"
                            style={{ borderRadius: "50%" }}
                            src={
                              user?.image != null
                                ? baseURL + user?.image
                                : "/frontend/images/default.jpg"
                            }
                            alt="img"
                          />
                        </figure>
                      </div>
                      <Formik
                        innerRef={formRef}
                        initialValues={{
                          [`comment${post.id}`]: "",
                        }}
                        // validationSchema={{
                        //   [`comment${post.id}`]: Yup.string()
                        //     .max(20, "User Name can be 20 characters")
                        //     .required("Username is required")
                        // }}
                        onSubmit={async (
                          values,
                          {
                            setErrors,
                            setStatus,
                            setSubmitting,
                            resetForm,
                            setFieldValue,
                          }
                        ) => {
                          const content = values[`comment${post.id}`].trim();
                          if (content.length > 0) {
                            socket.emit("commentPost", {
                              userId: user.id,
                              postId: post.id,
                              content,
                            });
                          } else {
                            toast.error("Please enter valid comment");
                          }
                          resetForm();
                        }}
                      >
                        {({
                          errors,
                          handleChange,
                          handleSubmit,
                          values,
                          setFieldValue,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            className="d-flex w-100"
                          >
                            <div className="form-group w-100">
                              <input
                                ref={commentRef}
                                type="text"
                                name={`comment${post.id}`}
                                id={`comment${post.id}`}
                                onChange={(event) => {
                                  setFieldValue(
                                    `comment${post.id}`,
                                    event.target.value
                                  );
                                }}
                                className="form-control form-control-in"
                                placeholder="Write your comment"
                                value={values[`comment${post.id}`]}
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-boarder mt-1 ms-1"
                              disabled={
                                values[`comment${post?.id}`]?.length === 0
                              }
                              style={{
                                color: "rgb(249, 19, 129)",
                                border: "1px solid",
                              }}
                            >
                              <i className="me-1 ri-send-plane-line align-middle"></i>
                              Send
                            </button>
                          </form>
                        )}
                      </Formik>
                    </div>
                    {post?.commentsPosts && post?.commentsPosts?.length > 0 && (
                      <div className="comment-list-box">
                        {post?.commentsPosts?.map((comment, index) => (
                          <div key={index} className="comment-list d-flex mt-2">
                            <div className="comment-list-in d-flex">
                              <Link
                                style={{ minWidth: "max-content" }}
                                to={
                                  post?.users.id != user.id &&
                                  `/profile-details/${comment?.usersComments?.id}`
                                }
                              >
                                <figure className="avatar">
                                  <img
                                    src={
                                      comment?.usersComments?.image
                                        ? `${baseURL}${comment?.usersComments?.image}`
                                        : "/frontend/images/default.jpg"
                                    }
                                    alt="img"
                                  />
                                </figure>
                              </Link>
                              <div className="comment-msg w-100 px-3">
                                <Link
                                  to={
                                    post?.users.id != user.id &&
                                    `/profile-details/${comment?.usersComments?.id}`
                                  }
                                >
                                  <div
                                    className="profile-name"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#F91381",
                                    }}
                                  >
                                    {comment?.usersComments?.username}
                                  </div>
                                </Link>
                                <div>{comment.content}</div>
                                <span
                                  style={{
                                    fontSize: "smaller",
                                    color: "#7b7b7b",
                                  }}
                                >
                                  {timeSince(comment.createdAt)}
                                </span>
                                {comment.show && comment.show === "1" && (
                                  <div className="reply-box">
                                    <div class="form-group d-flex w-100">
                                      <input
                                        type="text"
                                        name="reply"
                                        id="reply"
                                        value={reply}
                                        placeholder="Add a reply…"
                                        class="form-control form-control-in"
                                        onChange={(e) =>
                                          setReply(e.target.value)
                                        }
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-boarder ms-1"
                                        style={{
                                          color: "rgb(249, 19, 129)",
                                          border: "1px solid",
                                          width: "100px",
                                        }}
                                        onClick={() =>
                                          handleReply(
                                            user.id,
                                            post.id,
                                            comment.id
                                          )
                                        }
                                      >
                                        Reply
                                      </button>{" "}
                                    </div>
                                  </div>
                                )}
                                {comment.commentReply &&
                                  comment.commentReply.length > 0 &&
                                  comment.commentReply.map((reply) => (
                                    <>
                                      <Link
                                        style={{ minWidth: "max-content" }}
                                        to={
                                          post?.users.id != user.id &&
                                          `/profile-details/${reply?.usersComments?.id}`
                                        }
                                      >
                                        <figure className="avatar">
                                          <img
                                            src={
                                              reply?.usersComments?.image
                                                ? `${baseURL}${comment?.usersComments?.image}`
                                                : "/frontend/images/default.jpg"
                                            }
                                            alt="img"
                                          />
                                        </figure>
                                      </Link>
                                      <Link
                                        to={
                                          post?.users.id != user.id &&
                                          `/profile-details/${reply?.usersComments?.id}`
                                        }
                                      >
                                        <div
                                          className="profile-name"
                                          style={{
                                            fontWeight: "bold",
                                            color: "#F91381",
                                          }}
                                        >
                                          {reply?.usersComments?.username}
                                        </div>
                                      </Link>
                                      <div className="comment-list d-flex mt-2">
                                        <div className="comment-list-in d-flex">
                                          {reply.content}
                                        </div>
                                        <div className="comment-action ms-auto">
                                          {reply?.commentUserId == user.id && (
                                            <button
                                              className="iocn-btn"
                                              title="Delete Reply"
                                            >
                                              <i
                                                class="ri-delete-bin-fill"
                                                aria-hidden="true"
                                                onClick={() => {
                                                  deleteHandler(
                                                    reply?.id,
                                                    post.id
                                                  );
                                                }}
                                                style={{
                                                  color: "white",
                                                }}
                                              ></i>
                                            </button>
                                          )}
                                          {reply?.commentUserId == user.id && (
                                            <button
                                              className="iocn-btn"
                                              title="Edit Reply"
                                            >
                                              <i
                                                class="ri-edit-fill"
                                                aria-hidden="true"
                                                style={{
                                                  color: "white",
                                                }}
                                                onClick={() =>
                                                  editHandler(reply)
                                                }
                                              ></i>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                      <span
                                        style={{
                                          fontSize: "smaller",
                                          color: "#7b7b7b",
                                        }}
                                      >
                                        {timeSince(reply.createdAt)}
                                      </span>
                                    </>
                                  ))}
                              </div>
                            </div>
                            <div className="comment-action ms-auto">
                              {JSON.parse(comment?.likeCommentId)?.includes(
                                user?.id
                              ) ? (
                                <button className="iocn-btn">
                                  <i
                                    className="ri-heart-fill like-heart"
                                    style={{ color: "red" }}
                                    aria-hidden="true"
                                    onClick={() =>
                                      commentLikeHandler(comment, post.id)
                                    }
                                  ></i>
                                </button>
                              ) : (
                                <button
                                  className="iocn-btn"
                                  title="Like Comment"
                                >
                                  <i
                                    className="ri-heart-line"
                                    aria-hidden="true"
                                    onClick={() =>
                                      commentLikeHandler(comment, post.id)
                                    }
                                  ></i>
                                </button>
                              )}
                              {comment?.commentUserId == user.id ? (
                                <button
                                  className="iocn-btn"
                                  title="Delete Comment"
                                >
                                  <i
                                    class="ri-delete-bin-fill"
                                    aria-hidden="true"
                                    onClick={() => {
                                      deleteHandler(comment?.id, post.id);
                                    }}
                                    style={{
                                      color: "white",
                                    }}
                                  ></i>
                                </button>
                              ) : null}
                              <button
                                className="iocn-btn"
                                title="Reply Comment"
                                onClick={() =>
                                  replyHandler(post.id, comment.id)
                                }
                              >
                                <i
                                  class="ri-reply-fill"
                                  aria-hidden="true"
                                  // onClick={() => {
                                  //   deleteHandler(comment?.id);
                                  // }}
                                  style={{
                                    color: "white",
                                  }}
                                ></i>
                              </button>
                              {comment?.commentUserId == user.id ? (
                                <button
                                  className="iocn-btn"
                                  title="Edit Comment"
                                >
                                  <i
                                    class="ri-edit-fill"
                                    aria-hidden="true"
                                    style={{
                                      color: "white",
                                    }}
                                    onClick={() => editHandler(comment)}
                                  ></i>
                                </button>
                              ) : null}
                            </div>
                          </div>
                        ))}
                        <div className="d-flex w-100 form-group comment-reply mt-2">
                          {post?.editComment ? (
                            <>
                              {" "}
                              <input
                                ref={commentRef}
                                value={currentComment.content}
                                type="text"
                                name={`editComment`}
                                id={`editComment`}
                                className="form-control form-control-in"
                                placeholder="Edit your comment"
                                onChange={(e) =>
                                  setCurrentComment({
                                    ...currentComment,
                                    content: e.target.value,
                                  })
                                }
                                autoFocus={post?.editComment}
                              />{" "}
                              <button
                                type="button"
                                className="btn btn-boarder mt-1 ms-1"
                                onClick={() => updateComment(post.id)}
                              >
                                Update
                              </button>{" "}
                            </>
                          ) : null}
                        </div>
                        <div style={{ display: "flex" }}>
                          {post?.commentCount > 5 &&
                            currentCommentPage !==
                              Math.ceil(post?.commentCount / 5) && (
                              <div
                                onClick={() =>
                                  commentPaginationHandler(post.id)
                                }
                                style={{ marginTop: "10px", cursor: "pointer" }}
                              >
                                {" "}
                                Show More
                              </div>
                            )}
                          {post?.commentCount > 5 &&
                            currentCommentPage !== 1 && (
                              <div
                                onClick={() =>
                                  commentPaginationHandler(post.id, "less")
                                }
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                Show Less
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {console.log({ post: post.postLikes })}
                {post.showLikes && (
                  <>
                    {post?.postLikes && post?.postLikes?.length > 0 && (
                      <div className="comment-list-box">
                        {post?.postLikes?.map((user, index) => (
                          <div key={index} className="comment-list d-flex mt-2">
                            <div className="comment-list-in d-flex">
                              <Link
                                style={{ minWidth: "max-content" }}
                                to={
                                  post?.users.id != user.id &&
                                  `/profile-details/${user?.id}`
                                }
                              >
                                <figure className="avatar">
                                  <img
                                    src={
                                      user?.image
                                        ? `${baseURL}${user?.image}`
                                        : "/frontend/images/default.jpg"
                                    }
                                    alt="img"
                                  />
                                </figure>
                              </Link>
                              <div className="comment-msg w-100 px-3">
                                <Link
                                  to={
                                    post?.users.id != user.id &&
                                    `/profile-details/${user?.id}`
                                  }
                                >
                                  <div
                                    className="profile-name"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#F91381",
                                    }}
                                  >
                                    {user?.username}
                                  </div>
                                </Link>
                                <span
                                  style={{
                                    fontSize: "smaller",
                                    color: "#7b7b7b",
                                  }}
                                >
                                  {timeSince(user.createdAt)}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="d-flex w-100 form-group comment-reply mt-2">
                          {post?.editComment ? (
                            <>
                              {" "}
                              <input
                                ref={commentRef}
                                value={currentComment.content}
                                type="text"
                                name={`editComment`}
                                id={`editComment`}
                                className="form-control form-control-in"
                                placeholder="Edit your comment"
                                onChange={(e) =>
                                  setCurrentComment({
                                    ...currentComment,
                                    content: e.target.value,
                                  })
                                }
                                autoFocus={post?.editComment}
                              />{" "}
                              <button
                                type="button"
                                className="btn btn-boarder mt-1 ms-1"
                                onClick={() => updateComment(post.id)}
                              >
                                Update
                              </button>{" "}
                            </>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
      </InfiniteScroll>
      <div
        className="modal fade bd-example-modal-lg"
        id="exampleModal"
        // tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Post
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <MentionsInput
                  value={description}
                  ref={txtRef}
                  style={{ lineHeight: "normal", height: "200px" }}
                  className="form-control form-control-in"
                  onChange={handleChange}
                >
                  <Mention trigger="@" data={creators} />
                </MentionsInput>
              </div>
              <div className="button-c-post d-flex align-items-center justify-item-center p-2">
                <div className="button-c-p-g" style={{ width: "100%" }}>
                  <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                    {image &&
                      image.map((img, index) => (
                        <div
                          className="image-upload-wrap"
                          style={{ margin: "10px", width: "80%" }}
                        >
                          <button
                            onClick={() => imageCloseHandler(index)}
                            type="button"
                            className="iocn-btn"
                          >
                            <i className="ri-close-line"></i>
                          </button>
                          <ImagePreview imageValue={img} />
                        </div>
                      ))}
                    {video &&
                      video.map((vid, index) => (
                        <div
                          className="video-upload-wrap"
                          style={{
                            margin: "40px 10px 10px 10px",
                            width: "80%",
                          }}
                        >
                          <button
                            onClick={() => videoCloseHandler(index)}
                            type="button"
                            className="iocn-btn"
                          >
                            <i className="ri-close-line"></i>
                          </button>
                          <VideoPreview videoValue={vid} />
                        </div>
                      ))}
                  </div>
                  {vidProgress > 0 && vidProgress < 100 && (
                    <div className="upload-preview-box">
                      <CircularStatic progress={vidProgress} />
                    </div>
                  )}
                  {imgProgress > 0 && imgProgress < 100 && (
                    <div className="upload-preview-box">
                      <CircularStatic progress={imgProgress} />
                    </div>
                  )}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <>
                      {" "}
                      <button
                        className="btn"
                        type="button"
                        onClick={() => imageInputRef.current.click()}
                      >
                        <i className="ri-image-fill"></i> <span>Image</span>
                      </button>
                      <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={onSelectFile}
                        hidden
                        ref={imageInputRef}
                        accept="image/*"
                      />
                    </>
                    {openPicker && imageUrl && (
                      <ImageCrop
                        type="image"
                        file={imageUrl}
                        // setImage={setImage}
                        handleClickOpen={handleImageClickOpen}
                        handleClose={handleImageClose}
                        open={openPicker}
                        setOpen={setOpenPicker}
                        handleDoneClose={handleDoneClose}
                        setImageBlob={setImageBlob}
                      />
                    )}
                    <>
                      <button
                        className="btn"
                        type="button"
                        onClick={() => videoInputRef.current.click()}
                      >
                        <i className="ri-video-add-line"></i> <span>Video</span>
                      </button>
                      <input
                        type="file"
                        id="video"
                        name="video"
                        onChange={(event) => {
                          videoUpload(event);
                          //  setFieldValue("video", event.target.files[0]);
                        }}
                        hidden
                        ref={videoInputRef}
                        accept="video/*"
                      />
                    </>
                    <button
                      type="button"
                      style={{ position: "relative" }}
                      className="btn"
                      onClick={() => setOpenEmoji(!openEmoji)}
                    >
                      <i class="ri-emotion-line"></i>
                      <span>Emoji</span>
                    </button>
                    {openEmoji && (
                      <div ref={picker}>
                        <EmojiPicker
                          emojiStyle={{ position: "absolute" }}
                          onEmojiClick={emojiHandler}
                          theme="dark"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <label>Category</label>
                <Multiselect
                  className="form-control"
                  options={category} // Options to display in the dropdown
                  ref={categoryRef}
                  displayValue="name"
                  onSelect={handleSelect}
                  selectedValues={categoryData}
                  onRemove={handleRemove}
                />
                <div className="form-group mt-3">
                  <label>Type</label>
                  <select
                    name="privacy"
                    id=""
                    className="form-control"
                    value={privacy}
                    onChange={(e) => setPrivacy(e.target.value)}
                  >
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                id="closeModal"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-p"
                onClick={handleUpdate}
                disabled={loading}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <TipModal
        setOpenTipModal={setOpenTipModal}
        openTipModal={openTipModal}
        handleOpenTipModal={handleOpenTipModal}
        post={myPostData}
      ></TipModal>
      <DeletePostDialog
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        deletePost={deletePost}
      />
    </>
  );
};

export default PostList;
